import React, { useState } from 'react'

import SubItemListItem from './SubItemListItem/SubItemListItem'

import styles from './SubItemList.module.css'
import { Link } from 'react-router-dom'

const SubItemList = ({ icon, subItems, title, link='#', isSidebarHovered }) => {
    const [isActiveSubList, setIsActiveSubList] = useState(false)

    const onSubListToggle = () => {
        setIsActiveSubList((prevState) => !prevState)
    }

    const onSubItemMouseEnter = () => {
        setIsActiveSubList(true)
    }

    const onSubItemMouseLeave = () => {
        setIsActiveSubList(false)
    }

    return (
        <div
            className={`${styles.sidebarList} ${isActiveSubList ? styles.active : ''}`}
            onClick={onSubListToggle}
            onMouseEnter={onSubItemMouseEnter}
            onMouseLeave={onSubItemMouseLeave}
        >
            <Link to={link} className={`${styles.sidebarLink} `}>
                <span className={styles.linkIcon}>
                    <i className={icon}></i>
                </span>
                <div className={`${styles.fullTitle} ${isSidebarHovered ? styles.active : ''}`}>
                    <span>{title}</span>
                    <span className={styles.subListArrow}>
                        <i className="fas fa-chevron-right"></i>
                    </span>
                </div>
            </Link>

            <ul className={`${styles.subList} ${isActiveSubList ? styles.active : ''}`}>
                {subItems.map((subItem, i) => {
                    return (
                        <SubItemListItem
                            subItem={subItem}
                            key={i}
                            isActiveSubList={isActiveSubList}
                            isSidebarHovered={isSidebarHovered}
                        />
                    )
                })}
            </ul>
        </div>
    )
}

export default SubItemList
