import _ from 'lodash'

class OurNumber {
    constructor({ id, number, name, name_en, description, description_en }) {
        this.number = number
        this.name = name
        this.nameEn = name_en
        this.description = description
        this.descriptionEn = description_en
        this.slug = id

    }

    get titleTruncated() {
        return _.truncate(this.name, {
            length: 40,
            omission: '...'
        })
    }
}

export default OurNumber