import _ from 'lodash'

class Service {
    constructor({ id, gallery, image, slug, service_name, service_name_en, about_service_home, about_service_home_en, about_service, about_service_en, about_service_short, about_service_short_en, slug_en, pushers }) {
        this.id = id
        this.gallery = gallery
        this.image = image
        this.slug = slug
        this.serviceName = service_name
        this.serviceNameEn = service_name_en
        this.aboutServiceHome = about_service_home
        this.aboutServiceHomeEn = about_service_home_en
        this.aboutService = about_service
        this.aboutServiceEn = about_service_en
        this.aboutServiceShort = about_service_short
        this.aboutServiceShortEn = about_service_short_en
        this.slugEn = slug_en
        this.pushers = pushers
    }

    get titleTruncated() {
        return _.truncate(this.serviceName, {
            length: 40,
            omission: '...'
        })
    }
}

export default Service