import React, { useState } from 'react'

import _ from 'lodash'

import { Form } from 'formik'

import FormButton from './FormButton/FormButton'
import DeleteButton from './DeleteButton/DeleteButton'

import { LANGUAGES } from '../../../shared/constants'

import styles from './FormSection.module.css'
import LanguageBar from './LanguageBar/LanguageBar'

const FormSection = ({
    errors,
    status,
    touched,
    isSubmitting,
    values,
    setFieldValue,
    FormInputsEn,
    FormInputsSr,
    FormInputs,
    isEditForm,
    onDelete,
}) => {
    const { SERBIAN, ENGLISH } = LANGUAGES
    const [currentLang, setCurrentLang] = useState(SERBIAN)

    const Buttons = () => {
        return (
            <div className={styles.buttonsContainer}>
                {isEditForm && <DeleteButton onDelete={onDelete} />}
                <FormButton text="Submit" type="submit" />
            </div>
        )
    }

    const onChangeLang = (lang) => {
        setCurrentLang(lang)
    }

    const hasErrors = !_.isEmpty(errors)
    const isTouched = !_.isEmpty(touched)

    return (
        <Form>
            <LanguageBar onChangeLang={onChangeLang} currentLang={currentLang} />
            {currentLang === ENGLISH && (
                <FormInputsEn
                    errors={errors}
                    status={status}
                    touched={touched}
                    isSubmitting={isSubmitting}
                    values={values}
                    setFieldValue={setFieldValue}
                />
            )}
            {currentLang === SERBIAN && (
                <FormInputsSr
                    errors={errors}
                    status={status}
                    touched={touched}
                    isSubmitting={isSubmitting}
                    values={values}
                    setFieldValue={setFieldValue}
                />
            )}
            {FormInputs && (
                <FormInputs
                    errors={errors}
                    status={status}
                    touched={touched}
                    isSubmitting={isSubmitting}
                    values={values}
                    setFieldValue={setFieldValue}
                />
            )}
            <Buttons />
            {hasErrors && (
                <div className={styles.formError}>*** Fill all required fields correctly</div>
            )}
            {status && <div className={styles.formError}>*** {status}</div>}
        </Form>
    )
}

export default FormSection
