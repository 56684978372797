import React, { useEffect, useState } from 'react'
import { ourNumbersService } from '../../../services/ourNumbersService'

import SideList from '../../components/SideList/SideList'

const OurNumbersList = () => {
    const [ourNumbersList, setOurNumbersList] = useState([])

    useEffect(() => {
        const getOurNumbersList = async () => {
            try {
                const numbers = await ourNumbersService.getOurNumbers()

                setOurNumbersList(numbers)
            } catch (e) {
                console.error(e)
            }
        }

        getOurNumbersList()
    }, [])

    const onSearch = async (searchTerm) => {
        try {
            const numbers = await ourNumbersService.searchOurNumbers(searchTerm)
            setOurNumbersList(numbers)
        } catch (e) {
            console.error(e)
        }
    }

    return <SideList list={ourNumbersList} editLinkPath="/nase-brojke" onSearch={onSearch} />
}

export default OurNumbersList
