import React from 'react'

import TextEditor from '../../../components/TextEditor/TextEditor'

const FormInputsSr = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { historyText } = values

    return (
        <TextEditor
            data={historyText}
            setFieldValue={setFieldValue}
            label="Tekst"
            name="historyText"
        />
    )
}

export default FormInputsSr
