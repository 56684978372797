import React from 'react'

import Input from '../../../components/Input/Input'

const FormInputs = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { year } = values

    return (
        <>
             <Input
                name="year"
                id="year"
                type="text"
                errors={errors}
                touched={touched}
                label="Godina"
                setFieldValue={setFieldValue}
                value={year}
            />
        </>
    )
}

export default FormInputs
