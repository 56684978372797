import React from 'react'

import _ from 'lodash'

import SideListItem from './SideListItem/SideListItem'
import SearchInput from './SearchInput/SearchInput'

import styles from './SideList.module.css'


const SideList = ({ list, editLinkPath, onSearch, withLoadMoreButton, onLoadMore, showImage }) => {
    return (
        <section className={styles.sideList}>
            <SearchInput onSearch={onSearch}/>
            {_.isEmpty(list) ? (
                <div className={styles.noResults}>No results</div>
            ):(
                list.map((listItem, i) => (
                    <SideListItem key={i} item={listItem} path={editLinkPath} showImage={showImage}/>
                ))
            )}
            {
                withLoadMoreButton && (
                    <button className={styles.moreBtn} onClick={onLoadMore}>Prikaži više</button>
                )
            }
        </section>
    )
}

export default SideList
