import React, { useEffect, useState } from 'react'


import SideList from '../../../components/SideList/SideList'
import { certificateDocsService } from '../../../../services/certificateDocsService'

const CertificateDocsList = () => {
    const [certificateDocsList, setCertificateDocsList] = useState([])

    useEffect(() => {
        const getCertificateDocsList = async () => {
            try {
                const certificateDocs = await certificateDocsService.getOtherDocs()

                setCertificateDocsList(certificateDocs)
            } catch (e) {
                console.error(e)
            }
        }

        getCertificateDocsList()
    }, [])

    const onSearch = async (searchTerm) => {
        try {
            const certificateDocs = await certificateDocsService.searchOtherDocs(searchTerm)
            setCertificateDocsList(certificateDocs)
        } catch (e) {
            console.error(e)
        }
    }

    return  <SideList list={certificateDocsList} editLinkPath="/sertifikati/dokumenta" onSearch={onSearch}/>
}

export default CertificateDocsList
