import React, { useState } from 'react'

import Page from '../../components/Page/Page'
import { certificateDocsService } from '../../../services/certificateDocsService'
import CertificateDocsForm from './CertificateDocsForm/CertificateDocsForm'
import CertificateDocsList from './CertificateDocsList'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'

const AddDocuments = () => {
    const initialValues = {
        title: '',
        titleEn: '',
        image: '',
        document: '',
    }

    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')
        setIsLoading(true)
        try {
            await certificateDocsService.postCertificateDoc(values)

            setSubmitting(false)

            window.location.reload()
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    return (
        <Page title="Sertifikati - Novi unos dokumenta">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <CertificateDocsList />
                    <CertificateDocsForm initialValues={initialValues} onSubmit={onSubmit} />
                </>
            )}
        </Page>
    )
}

export default AddDocuments
