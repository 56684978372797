import React from 'react'
import { Link } from 'react-router-dom'

import styles from './SubItemListItem.module.css'
import SubItemList from '../SubItemList'

const SubItemListItem = ({subItem, isActiveSubList, isSidebarHovered}) => {
    const {link, icon, title, subList} = subItem
    return (
        <li>{
            subList ? (
                <SubItemList icon={icon} subItems={subList} link={link} title={title} isSidebarHovered/>
            ) : 
            <Link to={link} className={`${styles.subItem} ${isActiveSubList ? styles.active : ''}`}>
                <span className={styles.linkIcon}>
                    <i className={icon}></i>
                </span>
                <span>{title}</span>
            </Link>
            }
            
        </li>
    )
}

export default SubItemListItem
