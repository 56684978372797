import React from 'react'

import Input from '../../../components/Input/Input'

const FormInputs = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { expDate } = values

    return (
        <Input
            name="expDate"
            id="expDate"
            type="date"
            errors={errors}
            touched={touched}
            label="Datum objavljivanja"
            setFieldValue={setFieldValue}
            value={expDate}
        />
    )
}

export default FormInputs
