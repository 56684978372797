import React from 'react'
import Input from '../../../components/Input/Input'

const FormInputsEn = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { titleEn } = values
    return (
        <>
            <Input
                name="titleEn"
                id="titleEn"
                type="text"
                errors={errors}
                touched={touched}
                label="Naslov dokumenta En"
                setFieldValue={setFieldValue}
                value={titleEn}
            />
        </>
    )
}

export default FormInputsEn
