import React from 'react'

import Input from '../Input/Input'

import style from './YouTubeVideo.module.css'

const YouTubeVideo = ({ name, id, value, errors, touched, label }) => {
    return (
        <div className={style.youTube}>
            <Input
                type="text"
                label={label}
                name={name}
                id={id}
                value={value}
                errors={errors}
                touched={touched}
            />

            {value && <div className={style.preview} dangerouslySetInnerHTML={{ __html: value }} />}
        </div>
    )
}

export default YouTubeVideo
