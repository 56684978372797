import React, { useEffect, useState } from 'react'

import SideList from '../../components/SideList/SideList'
import { historyService } from '../../../services/historyService'

const HistoryList = () => {
    const [histories, setHistories] = useState([])

    useEffect(() => {
        const getHistories = async () => {
            try {
                const response = await historyService.getHistoryPosts()

                setHistories(response)
            } catch (e) {
                console.error(e)
            }
        }
        getHistories()
    }, [])

    const onSearch = async (searchTerm) => {
        try {
            const historyPosts = await historyService.searchHistory(searchTerm)
            setHistories(historyPosts)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <SideList list={histories} editLinkPath="/istorijat" onSearch={onSearch} />
        </>
    )
}

export default HistoryList
