import React from 'react'

import * as yup from 'yup'
import { Formik } from 'formik'

import FormWrapper from '../../../components/FormWrapper/FormWrapper'
import FormSection from '../../../components/FormSection/FormSection'
import FormInputsEn from './FormInputsEn'
import FormInputsSr from './FormInputsSr'
import FormInputs from './FormInputs'

const ServiceForm = ({ initialValues, onSubmit, onDelete, language, isEditForm = false }) => {
    const payloadSchema = yup.object().shape({
        gallery: yup.array().of(yup.string()),
        serviceName: yup.string().required(),
        serviceNameEn:yup.string().required(),
        aboutService: yup.string(),
        aboutServiceEn: yup.string(),
        aboutServiceShort: yup.string(),
        aboutServiceShortEn: yup.string(),
        image: yup.string().nullable(),
    })

    const _serviceForm = (props) => {

        return (
            <FormSection
                {...props}
                FormInputsEn={FormInputsEn}
                FormInputsSr={FormInputsSr}
                FormInputs={FormInputs}
                isEditForm={isEditForm}
                onDelete={onDelete}
            />
        )
    }

    return (
        <FormWrapper>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={payloadSchema}
                onSubmit={onSubmit}
            >
                {_serviceForm}
            </Formik>
        </FormWrapper>
    )
}

export default ServiceForm
