import React, { useState } from 'react'

import { newsService } from '../../../services/newsService'
import NewsForm from './NewsForm/NewsForm'
import Page from '../../components/Page/Page'
import NewsList from './NewsList'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'

const AddNews = () => {
    const initialValues = {
        title: '',
        titleEn: '',
        shortText: '',
        shortTextEn: '',
        text: '',
        textEn: '',
        gallery: [],
        image: '',
        publishDate: '',
        documents: [],
        documentsEn: [],
        youTubeVideo: '',
        youTubeVideoEn: '',
    }

    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')
        setIsLoading(true)

        try {
            await newsService.postNews(values)

            setSubmitting(false)

            window.location.reload()
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    return (
        <Page title="Vest - Novi unos">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <NewsList />
                    <NewsForm initialValues={initialValues} onSubmit={onSubmit} />
                </>
            )}
        </Page>
    )
}

export default AddNews
