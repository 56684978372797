import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ourNumbersService } from '../../../services/ourNumbersService'

import { servicesService } from '../../../services/servicesService'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'

import Page from '../../components/Page/Page'
import OurNumbersForm from './OurNumbersForm/OurNumbersForm'
import OurNumbersList from './OurNumbersList'

const EditOurNumber = () => {
    const [initialValues, setInitialValues] = useState({
        number: '',
        name: '',
        nameEn: '',
        description: '',
        descriptionEn: '',
    })

    const [isLoading, setIsLoading] = useState(false)

    const { id } = useParams()

    useEffect(() => {
        const getService = async () => {
            try {
                setIsLoading(true)
                const serviceData = await ourNumbersService.getOurNumber(id)

                const { number, name, nameEn, description, descriptionEn } = serviceData

                setInitialValues({
                    number: number ? number : '',
                    name: name ? name : '',
                    nameEn: nameEn ? nameEn : '',
                    description: description ? description : '',
                    descriptionEn: descriptionEn ? descriptionEn : '',
                })
                setIsLoading(false)
            } catch (e) {
                console.error(e)
            }
        }

        getService()
    }, [id])

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')
        setIsLoading(true)

        try {
            await ourNumbersService.updateNumber(values, id)

            window.location.reload()

            setSubmitting(false)
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    return (
        <Page title="Naše brojke - Izmeni unos">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <OurNumbersList />
                    <OurNumbersForm initialValues={initialValues} onSubmit={onSubmit} />
                </>
            )}
        </Page>
    )
}

export default EditOurNumber
