import CertificateDoc from './CertificateDoc'

class Certificates {
    constructor({ about_certificates, about_certificates_en, certificate_docs, }) {
        this.aboutCertificates = about_certificates
        this.aboutCertificatesEn = about_certificates_en
        this.certificateDocs = certificate_docs.map(certificateDoc => new CertificateDoc(certificateDoc))

    }
}

export default Certificates