import { api } from '../shared/api'
import OurNumber from '../models/OurNumber'

class OurNumbersService {


    async getOurNumbers() {
        const path = '/important-numbers'

        const numbers = await api.get(path)

        return numbers.map(number => new OurNumber(number))
    }

    async searchOurNumbers(searchTerm) {
        const path = '/important-numbers'

        const queryParams = {
            _sort: 'createdAt:DESC',
            name: searchTerm
        }

        const numbers = await api.get(path, queryParams)

        return numbers.map(number => new OurNumber(number))
    }

    async getOurNumber(id) {
        const path = `/important-numbers/${id}`

        const number = await api.get(path)

        return new OurNumber(number)

    }

    async postOurNumber(data) {
        const path = '/important-numbers'

        const {
            number,
            name,
            nameEn,
            description,
            descriptionEn
        } = data

        const payload = {
            number,
            name,
            name_en: nameEn,
            description,
            description_en: descriptionEn
        }

        const addedNumber = await api.post(path, payload)

        return new OurNumber(addedNumber)
    }

    async updateNumber(data, id) {
        const path = `/important-numbers/${id}`

        const {
            number,
            name,
            nameEn,
            description,
            descriptionEn
        } = data

        const payload = {
            number,
            name,
            name_en: nameEn,
            description,
            description_en: descriptionEn
        }

        const updatedNumber = await api.put(path, payload)

        return new OurNumber(updatedNumber)
    }
}


export const ourNumbersService = new OurNumbersService()