import React from 'react'

import TextEditor from '../../../components/TextEditor/TextEditor'

const FormInputsEn = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { aboutCertificatesEn} = values

    return (
        <>
            <TextEditor
                data={aboutCertificatesEn}
                setFieldValue={setFieldValue}
                label="O sertifikatima En"
                name="aboutCertificatesEn"
            />
        </>
    )
}

export default FormInputsEn
