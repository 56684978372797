import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import Page from '../../components/Page/Page'
import { galleryService } from '../../../services/galleryService'
import GalleryForm from './GalleryForm/GalleryForm'
import GalleryList from './GalleryList'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'

const EditGalleryItem = () => {
    const [initialValues, setInitialValues] = useState({
        title: '',
        titleEn: '',
        subtitle: '',
        subtitleEn: '',
        image: '',
        category: '',
    })

    const { id } = useParams()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const getGalleryItem = async () => {
            try {
                setIsLoading(true)
                const galleryItemData = await galleryService.getGalleryItem(id)

                const { title, titleEn, subtitle, subtitleEn, image, category } = galleryItemData

                setInitialValues({
                    title,
                    titleEn,
                    subtitle,
                    subtitleEn,
                    category,
                    image: image && image.id,
                })
                setIsLoading(false)
            } catch (e) {
                console.error(e)
            }
        }

        getGalleryItem()
    }, [id])

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')
        setIsLoading(true)

        try {
            const galleryItem = await galleryService.updateGalleryItem(values, id)

            setSubmitting(false)

            history.push(`/galerija/${galleryItem.id}`)

            window.location.reload()
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    const onDelete = async () => {
        try {
            await galleryService.deleteGalleryItem(id)

            history.push('/galerija')
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Page title="Galerija - Izmeni unos">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <GalleryList />
                    <GalleryForm
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onDelete={onDelete}
                        isEditForm
                    />
                </>
            )}
        </Page>
    )
}

export default EditGalleryItem
