import React from 'react'

import styles from './LanguageTab.module.css'

const LanguageTab = ({ lang, currentLang, onChangeLang }) => {

    const onLanguageChange = (e) => {
        const language = e.currentTarget.getAttribute('data-lang')

        onChangeLang(language)
        const formWrapper = document.querySelector('#formWrapper')
        formWrapper && formWrapper.scrollTo(0, 0)
    }

    const isActive = currentLang === lang

    return (
        <div
            className={`${styles.languageTab} ${isActive ? styles.active : ''}`}
            data-lang={lang}
            onClick={onLanguageChange}
        >
            {lang}
        </div>
    )
}

export default LanguageTab
