import React, { useState } from 'react'
import { historyService } from '../../../services/historyService'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'

import Page from '../../components/Page/Page'
import HistoryForm from './HistoryForm/HistoryForm'
import HistoryList from './HistoryListList'

const AddHistory = () => {
    const initialValues = {
        year: '',
        historyText: '',
        historyTextEn: '',
    }
    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')
        setIsLoading(true)
        try {
            await historyService.postHistory(values)
            setSubmitting(false)
            window.location.reload()
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    return (
        <Page title="Istorijat - Novi unos">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <HistoryList />
                    <HistoryForm initialValues={initialValues} onSubmit={onSubmit} />
                </>
            )}
        </Page>
    )
}

export default AddHistory
