import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { certificateDocsService } from '../../../../services/certificateDocsService'

import Page from '../../../components/Page/Page'
import CertificateDocsList from './CertificateDocsList'
import CertificateDocsForm from '../CertificateDocsForm/CertificateDocsForm'

const EditOtherDoc = () => {
    const [initialValues, setInitialValues] = useState({
        title: '',
        titleEn: '',
        image: '',
        document: ''
    })

    const { id } = useParams()
    const history = useHistory()

    useEffect(() => {
        const getCertificateDoc = async () => {
            try {
                const certificateDoc = await certificateDocsService.getOtherDoc(id)

                const { title,titleEn, image, document } = certificateDoc

                setInitialValues({
                    title,
                    titleEn,
                    image: image && image.id,
                    document: document && document.id
                })

            } catch (e) {
                console.error(e)
            }
        }

        getCertificateDoc()
    }, [id])

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')

        try {
          const certificateDoc =  await certificateDocsService.updateOtherDoc(values, id)

            setSubmitting(false)

            history.push(`/sertifikati/dokumenta/${certificateDoc.slug}`)

            window.location.reload()

        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    const onDelete = async () => {
        try {
            await certificateDocsService.deleteOtherDoc(id)

            history.push('/sertifikati/dokumenta')
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Page title="Sertifikati - Izmena unosa">
            <CertificateDocsList />
            <CertificateDocsForm initialValues={initialValues} onSubmit={onSubmit} onDelete={onDelete} isEditForm/>
        </Page>
    )
}

export default EditOtherDoc
