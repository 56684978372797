import React from 'react'

import LanguageTab from './LanguageTab/LanguageTab'

import { LANGUAGES } from '../../../../shared/constants'

import styles from './LanguageBar.module.css'

const { ENGLISH, SERBIAN } = LANGUAGES

const languages = [SERBIAN, ENGLISH]

const LanguageBar = ({ onChangeLang, currentLang }) => {
    return (
        <div className={styles.languageBar}>
            {languages.map((lang) => (
                <LanguageTab lang={lang} key={lang} onChangeLang={onChangeLang} currentLang={currentLang} />
            ))}
        </div>
    )
}

export default LanguageBar
