import React from 'react'

import FileInput from '../../../components/FileInput/FileInput'

const FormInputs = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { gallery, image } = values

    return (
        <>
            <FileInput
                setFieldValue={setFieldValue}
                fieldValueName="image"
                initialFileId={image}
                label="Slika"
                isImage
            />
        </>
    )
}

export default FormInputs
