import Contact from '../models/Contact'
import { api } from '../shared/api'


class ContactService {

    async getContacts(category) {
        const path = '/contact-infos'


        const queryParams = {
            category_contains: category
        }
        const contacts = await api.get(path, queryParams)

        return contacts.length > 0 ? contacts.map(contact => new Contact(contact)) : []
    }

    async postContact(data, category) {
        const path = '/contact-infos'

        const {
            name,
            nameEn,
            phone,
            fax,
            street,
            city,
            state,
            stateEn,
            email
        } = data

        const payload = {
            name,
            name_en: nameEn,
            category,
            phone,
            fax,
            street,
            city,
            state,
            state_en: stateEn,
            email
        }

        const contact = await api.post(path, payload)

        return new Contact(contact)
    }

    async updateContact(data, id, category) {
        const path = `/contact-infos/${id}`



        const {
            name,
            nameEn,
            phone,
            fax,
            street,
            city,
            state,
            stateEn,
            email
        } = data

        const payload = {
            name,
            name_en: nameEn,
            category,
            phone,
            fax,
            street,
            city,
            state,
            state_en: stateEn,
            email
        }



        const contact = await api.put(path, payload)

        return new Contact(contact)
    }



    async deleteContact(id) {
        const path = `/contact-infos/${id}`

        await api.delete(path)

    }

    async searchContacts(searchTerm) {
        const path = '/contact-infos'

        const queryParams = {
            _sort: 'createdAt:DESC',
            name_contains: searchTerm
        }

        const contacts = await api.get(path, queryParams)

        return contacts.map(contact => new Contact(contact))
    }

    async getContact(id) {
        const path = `/contact-infos/${id}`

        const contact = await api.get(path)

        return new Contact(contact)

    }
}

export const contactService = new ContactService()