import React, { useState } from 'react'

import styles from './SearchInput.module.css'

const SearchInput = ({onSearch}) => {
    const [searchTerm, setSearchTerm] = useState('')

    const onListSearch = (e) => {

        if(e.which === 13) {
            onSearch(searchTerm)
        }

    }

    const onChange = (e) => {
        const inputValue = e.target.value

        setSearchTerm(inputValue)
    }

    return (
        <div className={styles.searchInput}>
            <span className={styles.icon}>
                <i className="fas fa-search"></i>
            </span>{' '}
            <input type="text" placeholder="Search" value={searchTerm} onChange={onChange} onKeyPress={onListSearch}/>
        </div>
    )
}

export default SearchInput
