import React, { useEffect, useState } from 'react'

import SideList from '../../../components/SideList/SideList'
import { contactService } from '../../../../services/contactService'

const DirectionContactsList = () => {
    const [contacts, setContacts] = useState([])

    useEffect(() => {
        const getContacts = async () => {
            try {
                const contacts = await contactService.getContacts('direkcija')

                setContacts(contacts)
            } catch (e) {
                console.error(e)
            }
        }
        getContacts()
    }, [])

    const onSearch = async (searchTerm) => {
        try {
            const contactsList = await contactService.searchContacts(searchTerm)
            setContacts(contactsList)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <SideList list={contacts} editLinkPath="/kontakti/direkcija" onSearch={onSearch} />
        </>
    )
}

export default DirectionContactsList
