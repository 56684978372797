import React from 'react'
import Input from '../../../components/Input/Input'

const FormInputsSr = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { name, state } = values

    return (
        <>
            <Input
                name="name"
                id="name"
                type="text"
                errors={errors}
                touched={touched}
                label="Naziv"
                setFieldValue={setFieldValue}
                value={name}
            />
            <Input
                name="state"
                id="state"
                type="text"
                errors={errors}
                touched={touched}
                label="Država"
                setFieldValue={setFieldValue}
                value={state}
            />
        </>
    )
}

export default FormInputsSr
