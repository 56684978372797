import React, { useEffect, useState } from 'react'

import { aboutService } from '../../../services/aboutService'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'

import Page from '../../components/Page/Page'
import SideList from '../../components/SideList/SideList'
import AboutForm from './AboutForm/AboutForm'

const EditAbout = () => {
    const [initialValues, setInitialValues] = useState({
        aboutShort: '',
        aboutShortEn: '',
        ourFleet: '',
        ourFleetEn: '',
    })

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const getAbout = async () => {
            setIsLoading(true)
            try {
                const aboutData = await aboutService.getAbout()

                const { aboutShort, aboutShortEn, ourFleet, ourFleetEn } = aboutData

                setInitialValues({
                    aboutShort,
                    aboutShortEn,
                    ourFleet,
                    ourFleetEn,
                })
                setIsLoading(false)
            } catch (e) {
                console.error(e)
            }
        }

        getAbout()
    }, [])

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')
        setIsLoading(true)
        try {
            await aboutService.updateAbout(values)

            window.location.reload()

            setSubmitting(false)
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    return (
        <Page title="O nama">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <SideList list={[]} editLinkPath="" onSearch={() => {}} />
                    <AboutForm initialValues={initialValues} onSubmit={onSubmit} />
                </>
            )}
        </Page>
    )
}

export default EditAbout
