import React, {useState} from 'react'

import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'

import styles from './Page.module.css'

const Page = ({ title,children }) => {

    const [showMenuMob, setShowMenuMob] = useState(false)

    const onMenuMobToggle = () => {
        setShowMenuMob((prevState => !prevState))
    }

    return (
        <>
            <Header title={title} onMenuMobToggle={onMenuMobToggle}/>
            <div className={styles.page}>
                <Sidebar showMenuMob={showMenuMob}/>
                <main>{children}</main>
            </div>
        </>
    )
}

export default Page
