import React from 'react'
import { Switch } from 'react-router-dom'

import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import LogIn from '../pages/LogIn/LogIn'
import Dashboard from '../Dashboard/Dashboard'

import './App.css'

import EditCertificates from '../pages/Certificates/EditCertificates'
import AddCertificateDoc from '../pages/CertificateDocs/AddCertificateDoc'
import EditCertificateDoc from '../pages/CertificateDocs/EditCertificateDoc'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import UserProfile from '../UserProfile/UserProfile'

import AddHistory from '../pages/History/AddHistory'
import EditHistory from '../pages/History/EditHistory'

import EditAdvertisement from '../pages/Advertisements/EditAdvertisement'
import AddAdvertisement from '../pages/Advertisements/AddAdvertisement'

import AddPublicCall from '../pages/PublicCalls/AddPublicCall'
import EditPublicCalls from '../pages/PublicCalls/EditPublicCall'

import EditAbout from '../pages/About/EditAbout'
import EditAboutServices from '../pages/Services/AboutServices/EditAboutServices'
import EditService from '../pages/Services/EditService'

import AddNews from '../pages/News/AddNews'
import EditNews from '../pages/News/EditNews'
import AddGalleryItem from '../pages/Gallery/AddGalleryItem'
import EditGalleryItem from '../pages/Gallery/EditGalleryItem'
import AddDirectionContact from '../pages/Contacts/Direction/AddDirectionContact'
import EditDirectionContact from '../pages/Contacts/Direction/EditDirectionContact'
import AddAgencyContact from '../pages/Contacts/Agency/AddAgencyContact'
import EditAgencyContact from '../pages/Contacts/Agency/EditAgencyContact'
import EditOurNumber from '../pages/OurNumbers/EditOurNumber'
import AddOurNumber from '../pages/OurNumbers/AddOurNumber'
import EditOtherDoc from '../pages/CertificateDocs/Documents/EditCertificateDoc'
import AddOtherDoc from '../pages/CertificateDocs/Documents/AddDocuments'

const App = () => {
    return (
        <div className="App">
            <Switch>
                <PrivateRoute component={UserProfile} path="/profile" exact />
                <PrivateRoute component={EditOtherDoc} path="/sertifikati/dokumenta/:id" exact />
                <PrivateRoute component={AddOtherDoc} path="/sertifikati/dokumenta" exact />
                <PrivateRoute
                    component={EditCertificateDoc}
                    path="/sertifikati/sertifikati-dokumenta/:slug"
                    exact
                />
                <PrivateRoute
                    component={AddCertificateDoc}
                    path="/sertifikati/sertifikati-dokumenta"
                    exact
                />

                <PrivateRoute component={AddGalleryItem} path="/galerija" exact />
                <PrivateRoute component={EditGalleryItem} path="/galerija/:id" exact />

                <PrivateRoute component={AddHistory} path="/istorijat" exact />
                <PrivateRoute component={EditHistory} path="/istorijat/:id" exact />

                <PrivateRoute component={EditCertificates} path="/sertifikati" exact />

                <PrivateRoute component={AddNews} path="/vesti" exact />
                <PrivateRoute component={EditNews} path="/vesti/:slug" exact />

                <PrivateRoute component={AddAdvertisement} path="/oglasi" exact />
                <PrivateRoute component={EditAdvertisement} path="/oglasi/:slug" exact />
                <PrivateRoute component={AddPublicCall} path="/javni-pozivi" exact />
                <PrivateRoute component={EditPublicCalls} path="/javni-pozivi/:slug" exact />

                <PrivateRoute component={AddDirectionContact} path="/kontakti/direkcija" exact />
                <PrivateRoute
                    component={EditDirectionContact}
                    path="/kontakti/direkcija/:id"
                    exact
                />
                <PrivateRoute component={AddAgencyContact} path="/kontakti/agencije" exact />
                <PrivateRoute component={EditAgencyContact} path="/kontakti/agencije/:id" exact />

                <PrivateRoute component={EditOurNumber} path="/nase-brojke/:id" exact />
                <PrivateRoute component={AddOurNumber} path="/nase-brojke" exact />

                <PrivateRoute component={EditAboutServices} path="/usluge/o-uslugama" exact />
                <PrivateRoute component={EditService} path="/usluge/:slug" exact />

                <PrivateRoute component={EditAbout} path="/o-nama" exact />
                <PublicRoute component={ForgotPassword} path="/forgot-password" exact />
                <PublicRoute component={LogIn} path="/" exact />
            </Switch>
        </div>
    )
}

export default App
