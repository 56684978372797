class FormatDetails{
    constructor({hash, ext, mime, path, url, width, height}){
        this.hash = hash
        this.ext = ext
        this.mime = mime
        this.path = path
        this.url = url
        this.width = width
        this.height = height
    }
}

class ImageFormats {
    constructor({large, medium, small, thumbnail}) {
        this.large = large && new FormatDetails(large)
        this.medium = medium && new FormatDetails(medium)
        this.small = small && new FormatDetails(small)
        this.thumbnail = thumbnail && new FormatDetails(thumbnail)
    }
}


class Media {
    constructor({id, createdAt,ext, formats, hash, mime, name, updatedAt, url, width, height}){
        this.id  = id
        this.createdAt = createdAt
        this.ext = ext
        this.formats = formats ? new ImageFormats(formats) : null
        this.hash = hash
        this.mime = mime
        this.name = name
        this.updatedAt = updatedAt
        this.url = url
        this.width = width
        this.height = height
    }
}

export default Media