import React from 'react'

import Loader from 'react-loader-spinner'
import styles from './LoadingSpinner.module.css'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

export const LoadingSpinner = () => {
    return (
        <div className={styles.loaderContainer}>
            <Loader type="ThreeDots" color="#0000FF" height={80} width={80} />
        </div>
    )
}
