import React, { useState } from 'react'

import Page from '../../components/Page/Page'
import { galleryService } from '../../../services/galleryService'
import GalleryForm from './GalleryForm/GalleryForm'
import GalleryList from './GalleryList'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'

const AddGalleryItem = () => {
    const initialValues = {
        title: '',
        titleEn: '',
        subtitle: '',
        subtitleEn: '',
        image: '',
        category: '',
    }
    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')
        setIsLoading(true)
        try {
            await galleryService.postGalleryItem(values)

            setSubmitting(false)

            window.location.reload()
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    return (
        <Page title="Galerija - Novi unos">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <GalleryList />
                    <GalleryForm initialValues={initialValues} onSubmit={onSubmit} />
                </>
            )}
        </Page>
    )
}

export default AddGalleryItem
