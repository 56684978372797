import { api } from '../shared/api'
import CertificateDoc from '../models/CertificateDoc'

class CertificateDocsService {

    async getCertificateDocs() {
        const path = '/certificate-docs'

        const certificateDocs = await api.get(path)

        return certificateDocs.map(doc => new CertificateDoc(doc))
    }

    async getOtherDocs() {
        const path = '/other-certificates'

        const certificateDocs = await api.get(path)

        console.log(certificateDocs)

        return certificateDocs.map(doc => new CertificateDoc(doc))
    }

    async postCertificateDoc(data) {
        const path = '/certificate-docs'

        const {
            title,
            titleEn,
            image,
            document
        } = data

        const payload = {
            title,
            title_en: titleEn,
            image,
            document
        }

        const certificateDoc = await api.post(path, payload)

        return new CertificateDoc(certificateDoc)
    }

    async postOtherDoc(data) {
        const path = '/other-certificates'

        const {
            title,
            titleEn,
            image,
            document
        } = data

        const payload = {
            title,
            title_en: titleEn,
            image,
            document
        }

        const certificateDoc = await api.post(path, payload)

        return new CertificateDoc(certificateDoc)
    }

    async getCertificateDoc(slug) {
        const path = `/certificate-docs?slug=${slug}`

        const certificateDocs = await api.get(path)

        return new CertificateDoc(certificateDocs[0])

    }

    async getOtherDoc(id) {
        const path = `/other-certificates/${id}`

        const certificateDoc = await api.get(path)

        return new CertificateDoc(certificateDoc)

    }

    async updateCertificateDoc(data, id) {
        const path = `/certificate-docs/${id}`

        const {
            title,
            titleEn,
            image,
            document
        } = data

        const payload = {
            title,
            title_en: titleEn,
            image,
            document
        }

        const updatedDoc = await api.put(path, payload)

        return new CertificateDoc(updatedDoc)
    }

    async updateOtherDoc(data, id) {
        const path = `/other-certificates/${id}`

        const {
            title,
            titleEn,
            image,
            document
        } = data

        const payload = {
            title,
            title_en: titleEn,
            image,
            document
        }

        const updatedDoc = await api.put(path, payload)

        return new CertificateDoc(updatedDoc)
    }

    async deleteCertificateDoc(id) {
        const path = `/certificate-docs/${id}`

        await api.delete(path)

    }

    async deleteOtherDoc(id) {
        const path = `/other-certificates/${id}`

        await api.delete(path)

    }

    async searchCertificateDocs(searchTerm) {
        const path = '/certificate-docs'

        const queryParams = {
            _sort: 'createdAt:DESC',
            title: searchTerm
        }

        const certificateDocs = await api.get(path, queryParams)

        return certificateDocs.map(doc => new CertificateDoc(doc))
    }

    async searchOtherDocs(searchTerm) {
        const path = '/other-certificates'

        const queryParams = {
            _sort: 'createdAt:DESC',
            title: searchTerm
        }

        const certificateDocs = await api.get(path, queryParams)

        return certificateDocs.map(doc => new CertificateDoc(doc))
    }
}

export const certificateDocsService = new CertificateDocsService()