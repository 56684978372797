import React from 'react'

import FileInput from '../../../components/FileInput/FileInput'
import SelectOptionsDropdown from '../../../components/SelectOptionsDropdown/SelectOptionsDropdown'

const FormInputs = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { image, category } = values

    const options = [
        {
            value: '',
            name: 'Izaberi',
        },
        {
            value: 'new',
            name: 'Flota danas',
        },
        {
            value: 'old',
            name: 'Flota nekad',
        },
    ]

    return (
        <>
            <FileInput
                setFieldValue={setFieldValue}
                fieldValueName="image"
                initialFileId={image}
                label="Slika"
                isImage
            />
            <SelectOptionsDropdown
                label="Kategorija"
                value={category}
                selectDropdownList={options}
                setFieldValue={setFieldValue}
                fieldValueName="category"
            />
        </>
    )
}

export default FormInputs
