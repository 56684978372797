import { api } from '../shared/api'
import PublicCall from '../models/PublicCalls'


class PublicCallsService {

    async getPublicCalls(startParam, limitParam) {
        const path = `/public-calls?_start=${startParam}&_limit=${limitParam}`

        const queryParams = {
            _sort: 'exp_date:ASC',
        }

        const response = await api.get(path, queryParams)

        const { total, start, limit, posts } = response

        return {
            total,
            start,
            limit,
            posts: posts.map(publicCall => new PublicCall(publicCall))
        }

    }

    async postPublicCall(data) {
        const path = '/public-calls'

        const {
            title,
            titleEn,
            text,
            textEn,
            expDate,
        } = data

        const payload = {
            title,
            title_en: titleEn,
            text,
            text_en: textEn,
            exp_date: expDate,
        }

        const publicCall = await api.post(path, payload)

        return new PublicCall(publicCall)
    }

    async getSinglePublicCall(slug) {
        const path = `/public-calls?slug=${slug}`

        const response = await api.get(path)

        const { total, start, limit, posts } = response


        return new PublicCall(posts[0])

    }

    async updatePublicCall(data, id) {
        const path = `/public-calls/${id}`

        const {
            title,
            titleEn,
            text,
            textEn,
            expDate,
        } = data

        const payload = {
            title,
            title_en: titleEn,
            text,
            text_en: textEn,
            exp_date: expDate,
        }

        const publicCall = await api.put(path, payload)

        return new PublicCall(publicCall)
    }

    async deletePublicCall(id) {
        const path = `/public-calls/${id}`

        await api.delete(path)

    }

    async searchPublicCalls(searchTerm) {
        const path = '/public-calls'

        const queryParams = {
            _sort: 'exp_date:ASC',
            title_contains: searchTerm
        }

        const { posts } = await api.get(path, queryParams)

        return posts.map(publicCall => new PublicCall(publicCall))
    }
}

export const publicCallsService = new PublicCallsService()