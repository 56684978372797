import React, { useEffect, useState } from 'react'

import SideList from '../../components/SideList/SideList'
import { publicCallsService } from '../../../services/publicCallsService'

const LIMIT = 30

const PublicCallsList = () => {
    const [publicCallsList, setPublicCallsList] = useState([])
    const [start, setStart] = useState(0)
    const [hasMore, setHasMore] = useState(false)

    useEffect(() => {
        const getPublicCalls = async () => {
            try {
                const response = await publicCallsService.getPublicCalls(0, LIMIT)
                const { total, posts } = response

                setPublicCallsList(posts)
                setHasMore(total > posts.length)
                setStart(LIMIT)
            } catch (e) {
                console.error(e)
            }
        }
        getPublicCalls()
    }, [])

    const onLoadMore = async () => {
        try {
            const response = await publicCallsService.getPublicCalls(start, LIMIT)
            const { total, posts } = response

            setPublicCallsList((prevState) => [...prevState, ...posts])
            setStart(start + LIMIT)
            setHasMore(total > [...publicCallsList, ...posts].length)
        } catch (e) {
            console.error(e)
        }
    }

    const onSearch = async (searchTerm) => {
        try {
            const ads = await publicCallsService.searchPublicCalls(searchTerm)
            setPublicCallsList(ads)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <SideList
                list={publicCallsList}
                editLinkPath="/javni-pozivi"
                onSearch={onSearch}
                withLoadMoreButton={hasMore}
                onLoadMore={onLoadMore}
            />
        </>
    )
}

export default PublicCallsList
