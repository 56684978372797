import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { servicesService } from '../../../services/servicesService'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'

import Page from '../../components/Page/Page'
import ServiceForm from './ServiceForm/ServiceForm'
import ServicesList from './ServicesList'

const EditService = () => {
    const [serviceId, setServiceId] = useState('')
    const [initialValues, setInitialValues] = useState({
        gallery: [],
        serviceName: '',
        serviceNameEn: '',
        aboutService: '',
        aboutServiceHome: '',
        aboutServiceHomeEn: '',
        aboutServiceEn: '',
        aboutServiceShort: '',
        aboutServiceShortEn: '',
        image: '',
    })

    const [isLoading, setIsLoading] = useState(false)

    const { slug } = useParams()

    useEffect(() => {
        const getService = async () => {
            try {
                setIsLoading(true)
                const serviceData = await servicesService.getService(slug)

                const {
                    id,
                    gallery,
                    serviceName,
                    serviceNameEn,
                    aboutServiceHome,
                    aboutServiceHomeEn,
                    aboutService,
                    aboutServiceEn,
                    aboutServiceShort,
                    aboutServiceShortEn,
                    image,
                } = serviceData

                setInitialValues({
                    gallery: gallery.map((item) => item.id),
                    serviceName,
                    serviceNameEn,
                    aboutService: aboutService ? aboutService : '',
                    aboutServiceHome: aboutServiceHome ? aboutServiceHome : '',
                    aboutServiceHomeEn: aboutServiceHomeEn ? aboutServiceHomeEn : '',
                    aboutServiceEn: aboutServiceEn ? aboutServiceEn : '',
                    aboutServiceShort: aboutServiceShort ? aboutServiceShort : '',
                    aboutServiceShortEn: aboutServiceShortEn ? aboutServiceShortEn : '',
                    image: image && image.id,
                })

                setIsLoading(false)

                setServiceId(id)
            } catch (e) {
                console.error(e)
            }
        }

        getService()
    }, [slug])

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')
        setIsLoading(true)

        try {
            await servicesService.updateService(values, serviceId)

            window.location.reload()

            setSubmitting(false)
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    return (
        <Page title="Usluge - Izmeni unos">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <ServicesList />
                    <ServiceForm initialValues={initialValues} onSubmit={onSubmit} />
                </>
            )}
        </Page>
    )
}

export default EditService
