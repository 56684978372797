import { api } from '../shared/api'
import Advertisement from '../models/Advertisement'


class AdvertisementsService {

    async getAdvertisements(startParam, limitParam) {
        const path = `/ads?_start=${startParam}&_limit=${limitParam}`

        const queryParams = {
            _sort: 'exp_date:ASC',
        }

        const response = await api.get(path, queryParams)

        const { total, start, limit, posts } = response

        return {
            total,
            start,
            limit,
            posts: posts.map(advertisement => new Advertisement(advertisement))
        }

    }

    async postAdvertisement(data) {
        const path = '/ads'

        const {
            title,
            titleEn,
            text,
            textEn,
            expDate,
        } = data

        const payload = {
            title,
            title_en: titleEn,
            text,
            text_en: textEn,
            exp_date: expDate,
        }

        const advertisement = await api.post(path, payload)

        return new Advertisement(advertisement)
    }

    async getSingleAdvertisement(slug) {
        const path = `/ads?slug=${slug}`

        const response = await api.get(path)

        const { total, start, limit, posts } = response


        return new Advertisement(posts[0])

    }

    async updateAdvertisement(data, id) {
        const path = `/ads/${id}`

        const {
            title,
            titleEn,
            text,
            textEn,
            expDate,
        } = data

        const payload = {
            title,
            title_en: titleEn,
            text,
            text_en: textEn,
            exp_date: expDate,
        }

        const advertisement = await api.put(path, payload)

        return new Advertisement(advertisement)
    }

    async deleteAdvertisement(id) {
        const path = `/ads/${id}`

        await api.delete(path)

    }

    async searchAdvertisements(searchTerm) {
        const path = '/ads'

        const queryParams = {
            _sort: 'exp_date:ASC',
            title_contains: searchTerm
        }

        const { posts } = await api.get(path, queryParams)

        return posts.map(advertisement => new Advertisement(advertisement))
    }
}

export const advertisementsService = new AdvertisementsService()