import React from 'react'

import TextEditor from '../../../components/TextEditor/TextEditor'

const FormInputsSr = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { aboutCertificates} = values

    return (
        <>
            <TextEditor
                data={aboutCertificates}
                setFieldValue={setFieldValue}
                label="O sertifikatima"
                name="aboutCertificates"
            />
        </>
    )
}

export default FormInputsSr
