import { api } from '../shared/api'
import Certificates from '../models/Certificates'

class CertificatesService {


    async getCertificates() {
        const path = '/certificates'

        const certificates = await api.get(path)

        return new Certificates(certificates)
    }


    async updateCertificates(data) {
        const path = '/certificates'

        const {
          aboutCertificates,
          aboutCertificatesEn,
          certificateDocs
        } = data

        const payload = {
           about_certificates:aboutCertificates,
           about_certificates_en: aboutCertificatesEn,
           certificateDocs
        }

        const updatedCertificates = await api.put(path, payload)

        return new Certificates(updatedCertificates)
    }
}

export const certificatesService = new CertificatesService()