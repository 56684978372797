import React from 'react'

import TextEditor from '../../../components/TextEditor/TextEditor'

const FormInputsEn = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { aboutShortEn, ourFleetEn } = values

    return (
        <>
            <TextEditor
                data={aboutShortEn}
                setFieldValue={setFieldValue}
                label="O nama EN"
                name="aboutShortEn"
            />
            <TextEditor
                data={ourFleetEn}
                setFieldValue={setFieldValue}
                label="Naša flota EN - naslovna"
                name="ourFleetEn"
            />
        </>
    )
}

export default FormInputsEn
