import React, { useState } from 'react'
import { AppContext } from './_context/AppContext'
import { storage } from '../shared/storage'

const AppStore = ({ children }) => {
    const [user, setUser] = useState(storage.get('user'))

    const onUserLogIn = (loggedUser) => {
        setUser(loggedUser)
        storage.save('user', loggedUser)
        storage.save('userToken', loggedUser && loggedUser.jwt)
    }

    const onUserLogOut = () => {

        setUser(null)
        storage.remove('user')
        storage.remove('userToken')
    }

    return (
        <AppContext.Provider
            value={{
                user,
                onUserLogIn,
                onUserLogOut
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export default AppStore