import UserRole from './UserRole'

class User {
    constructor(jwt, { id, email, blocked, confirmed, username, role }) {
        this.jwt = jwt
        this.userId = id
        this.email = email
        this.isBlocked = blocked
        this.isConfirmed = confirmed
        this.username = username
        this.userRole = new UserRole(role)
    }
}

export default User