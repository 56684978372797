import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { Formik, Form } from 'formik'
import * as yup from 'yup'

import LoginInput from './LoginInput/LoginInput'

import styles from './LogInForm.module.css'
import { AppContext } from '../../../_context/AppContext'
import { userService } from '../../../../services/userService'

const LogInForm = () => {
    const { onUserLogIn } = useContext(AppContext)
    const history = useHistory()

    const payloadSchema = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().required(),
    })

    const initialValues = {
        email: '',
        password: '',
    }

    const onSubmit = async (values, { setStatus, setSubmitting }) => {
        setStatus('')

        try {
            const { email, password } = values
            const user = await userService.userLogIn(email, password)

            onUserLogIn(user)

            setSubmitting(false)

            history.push('/o-nama')
        } catch (err) {
            setStatus(err.response.data.message)
            console.log(err)
        }
    }

    const _logInForm = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
        const { email, password } = values
        return (
            <div className={styles.loginForm}>
                <Form>
                    <div className={styles.loginInputs}>
                        <LoginInput
                            id="email"
                            type="email"
                            label="Email"
                            name="email"
                            value={email}
                            errors={errors}
                            touched={touched}
                        />
                        <LoginInput
                            id="password"
                            type="password"
                            label="Password"
                            name="password"
                            value={password}
                            errors={errors}
                            touched={touched}
                        />
                        <div className={styles.errMsg}>{status ? `${status}!` : ''}</div>
                    </div>
                    <button type="submit" className={styles.button}>
                        Log in
                    </button>
                    {/* <Link to="/forgot-password" className={styles.forgotPass}>Forgot Password</Link> */}
                </Form>
            </div>
        )
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={payloadSchema}
            onSubmit={onSubmit}
        >
            {_logInForm}
        </Formik>
    )
}

export default LogInForm
