import React, { useState } from 'react'

import Page from '../../components/Page/Page'
import { advertisementsService } from '../../../services/advertisementsService'
import AdvertisementForm from './AdvertisementForm/AdvertisementForm'
import AdvertisementsList from './AdvertisementsList'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'

const AddAdvertisement = () => {
    const initialValues = {
        title: '',
        titleEn: '',
        text: '',
        textEn: '',
        expDate: '',
    }
    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')
        setIsLoading(true)
        try {
            await advertisementsService.postAdvertisement(values)

            setSubmitting(false)

            window.location.reload()
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    return (
        <Page title="Oglas - Novi unos">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <AdvertisementsList />
                    <AdvertisementForm initialValues={initialValues} onSubmit={onSubmit} />
                </>
            )}
        </Page>
    )
}

export default AddAdvertisement
