import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import Page from '../../components/Page/Page'
import NewsList from './NewsList'
import NewsForm from './NewsForm/NewsForm'
import { newsService } from '../../../services/newsService'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'

const EditNews = () => {
    const [id, setId] = useState('')
    const [initialValues, setInitialValues] = useState({
        title: '',
        titleEn: '',
        shortText: '',
        shortTextEn: '',
        documents: [],
        documentsEn: [],
        text: '',
        textEn: '',
        gallery: [],
        image: '',
        publishDate: '',
        youTubeVideo: '',
        youTubeVideoEn: '',
    })

    const { slug } = useParams()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const getNews = async () => {
            try {
                setIsLoading(true)
                const newsData = await newsService.getSingleNews(slug)

                const {
                    id,
                    title,
                    titleEn,
                    shortText,
                    shortTextEn,
                    documents,
                    documentsEn,
                    text,
                    textEn,
                    gallery,
                    image,
                    publishDate,
                    youTubeVideo,
                    youTubeVideoEn,
                } = newsData

                setInitialValues({
                    title,
                    titleEn,
                    shortText,
                    shortTextEn,
                    documents: documents.map((item) => item.id),
                    documentsEn: documentsEn.map((item) => item.id),
                    text,
                    textEn,
                    publishDate,
                    image: image && image.id,
                    gallery: gallery.map((item) => item.id),
                    youTubeVideo,
                    youTubeVideoEn,
                })
                setIsLoading(false)

                setId(id)
            } catch (e) {
                console.error(e)
            }
        }

        getNews()
    }, [slug])

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')
        setIsLoading(true)

        try {
            const news = await newsService.updateNews(values, id)

            setSubmitting(false)

            history.push(`/vesti/${news.slug}`)

            window.location.reload()
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    const onDelete = async () => {
        try {
            await newsService.deleteNews(id)

            history.push('/vesti')
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Page title="Vest - Izmeni unos">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <NewsList />
                    <NewsForm
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onDelete={onDelete}
                        isEditForm
                    />
                </>
            )}
        </Page>
    )
}

export default EditNews
