import React from 'react'

import Input from '../../../components/Input/Input'
import TextEditor from '../../../components/TextEditor/TextEditor'

const FormInputsEn = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { nameEn, descriptionEn } = values

    return (
        <>
            <Input
                name="nameEn"
                id="nameEn"
                type="text"
                errors={errors}
                touched={touched}
                label="Naslov EN"
                setFieldValue={setFieldValue}
                value={nameEn}
            />
            <Input
                name="descriptionEn"
                id="descriptionEn"
                type="text"
                errors={errors}
                touched={touched}
                label="Kratak opis EN"
                setFieldValue={setFieldValue}
                value={descriptionEn}
            />
        </>
    )
}

export default FormInputsEn
