import React, { useState } from 'react'
import { contactService } from '../../../../services/contactService'
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner'

import Page from '../../../components/Page/Page'
import ContactForm from '../ContactForm/ContactForm'
import AgencyContactsList from './AgencyContactsList'

const AddAgencyContact = () => {
    const initialValues = {
        name: '',
        nameEn: '',
        phone: '',
        fax: '',
        street: '',
        city: '',
        state: '',
        stateEn: '',
        email: '',
    }
    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')

        setIsLoading(true)
        try {
            await contactService.postContact(values, 'agencija')
            setSubmitting(false)
            window.location.reload()
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    return (
        <Page title="Kontakt Predstavništva i agencije - Novi unos">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <AgencyContactsList />
                    <ContactForm initialValues={initialValues} onSubmit={onSubmit} />
                </>
            )}
        </Page>
    )
}

export default AddAgencyContact
