import {api} from '../shared/api'
import User from '../models/User'

class UserService{

    async userLogIn(email, password) {
        const path='/auth/local'
        const data = {
            identifier: email,
            password
        }
        const loggedUser = await api.post(path, data)

        const {jwt, user} = loggedUser
        return new User(jwt, user)

    }

    async editUserProfile(id, email, username, password) {
        const path = `/users/${id}`

        const data={
            email,
            username, 
            password
        }

        const updatedUser = await api.put(path, data)

        console.log(updatedUser)

        return updatedUser
    }

    async forgotPassword(email) {
        const path = '/auth/forgot-password'

        console.log(email)

        const data = {
            email,
        }

        await api.post(path, data)

    }
}

export const userService = new UserService()