class About {
    constructor({ about_short, about_short_en, our_fleet, our_fleet_en }) {
        this.aboutShort = about_short
        this.aboutShortEn = about_short_en
        this.ourFleet = our_fleet ? our_fleet : ''
        this.ourFleetEn = our_fleet_en ? our_fleet_en : ''

    }
}

export default About