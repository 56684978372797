import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import Page from '../../components/Page/Page'
import { advertisementsService } from '../../../services/advertisementsService'
import AdvertisementForm from './AdvertisementForm/AdvertisementForm'
import AdvertisementsList from './AdvertisementsList'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'

const EditAdvertisement = () => {
    const [id, setId] = useState('')
    const [initialValues, setInitialValues] = useState({
        title: '',
        titleEn: '',
        text: '',
        textEn: '',
        expDate: '',
    })
    const [isLoading, setIsLoading] = useState(false)

    const { slug } = useParams()
    const history = useHistory()

    useEffect(() => {
        const getAdvertisement = async () => {
            setIsLoading(true)
            try {
                const adData = await advertisementsService.getSingleAdvertisement(slug)

                const { id, title, titleEn, text, textEn, expDate } = adData

                setInitialValues({
                    title,
                    titleEn,
                    text,
                    textEn,
                    expDate,
                })

                setIsLoading(false)

                setId(id)
            } catch (e) {
                console.error(e)
            }
        }

        getAdvertisement()
    }, [slug])

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')
        setIsLoading(true)

        try {
            const advertisement = await advertisementsService.updateAdvertisement(values, id)

            setSubmitting(false)

            history.push(`/oglasi/${advertisement.slug}`)

            window.location.reload()
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    const onDelete = async () => {
        try {
            await advertisementsService.deleteAdvertisement(id)

            history.push('/oglasi')
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Page title="Oglasi - Izmeni unos">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <AdvertisementsList />
                    <AdvertisementForm
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onDelete={onDelete}
                        isEditForm
                    />
                </>
            )}
        </Page>
    )
}

export default EditAdvertisement
