import React from 'react'

import TextEditor from '../../../components/TextEditor/TextEditor'

const FormInputsEn = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { historyTextEn } = values

    return (
        <TextEditor
            data={historyTextEn}
            setFieldValue={setFieldValue}
            label="Tekst En"
            name="historyTextEn"
        />
    )
}

export default FormInputsEn
