import React from 'react'

import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import MyUploadAdapter from './MyUploadAdapter'


import './TextEditor.css'
import FullScreen from './FullScreen/FullScreen'

const TextEditor = ({ data, setFieldValue, label, name, isModalOpen=false }) => {

    return (
        <div className="text-editor">
            <div className="editor-label">{label}:</div>
           { !isModalOpen &&<FullScreen data={data} label={label} setFieldValue={setFieldValue} name={name} />}
            <CKEditor
                editor={ClassicEditor}
                data={data}
                onInit={(editor) => {
                    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                        return new MyUploadAdapter(loader)
                    }

                    // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                    const data = editor.getData()
                    setFieldValue(name, data)
                }}
                onBlur={(event, editor) => {
                    // console.log('Blur.', editor)
                }}
                onFocus={(event, editor) => {
                    // console.log('Focus.', editor)
                }}
            />
        </div>
    )
}

export default TextEditor
