import React from 'react'

import Page from '../../../components/Page/Page'
import { certificateDocsService } from '../../../../services/certificateDocsService'
import CertificateDocsForm from '../CertificateDocsForm/CertificateDocsForm'
import CertificateDocsList from './CertificateDocsList'

const AddOtherDoc = () => {
    const initialValues = {
        title: '',
        titleEn: '',
        image: '',
        document: '',
    }

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')

        try {
            await certificateDocsService.postOtherDoc(values)


            setSubmitting(false)

            window.location.reload()

        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    return (
        <Page title="Sertifikati - Novi unos dokumenta">
            <CertificateDocsList />
            <CertificateDocsForm initialValues={initialValues} onSubmit={onSubmit} />
        </Page>
    )
}

export default AddOtherDoc
