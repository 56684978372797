import { BASE_URL } from '../../../shared/constants'
import { storage } from '../../../shared/storage'

class MyUploadAdapter {
    constructor(loader) {
        // CKEditor 5's FileLoader instance.
        this.loader = loader

        // URL where to send files.
        this.url = `${BASE_URL}/upload`
    }

    // Starts the upload process.
    upload() {
        return new Promise(async (resolve, reject) => {
            this.loader.file.then((file) => {
                this._initRequest()
                this._initListeners(resolve, reject, file)
                this._sendRequest(file)
            })
        })

    }

    // Aborts the upload process.
    abort() {
        console.log('nesto')

        if (this.xhr) {
            this.xhr.abort()
        }
    }

    // Example implementation using XMLHttpRequest.
    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest()
        const userToken = storage.get('userToken')

        xhr.open('POST', this.url, true)
        xhr.setRequestHeader('Authorization', `${userToken ? `Bearer ${userToken}` : ''}`)
        xhr.responseType = 'json'
    }

    // Initializes XMLHttpRequest listeners.
    _initListeners(resolve, reject) {
        const xhr = this.xhr
        const loader = this.loader
        const genericErrorText = `Couldn't upload file: ${loader.file.name}.`

        xhr.addEventListener('error', () => reject(genericErrorText))
        xhr.addEventListener('abort', () => reject())
        xhr.addEventListener('load', () => {
            const response = xhr.response

            if (!response || response.error) {
                return reject(response && response.error ? response.error.message : genericErrorText)
            }

            // If the upload is successful, resolve the upload promise with an object containing
            // at least the 'default' URL, pointing to the image on the server.
            resolve({
                default: response.url
            })
        })

        if (xhr.upload) {
            xhr.upload.addEventListener('progress', evt => {
                if (evt.lengthComputable) {
                    loader.uploadTotal = evt.total
                    loader.uploaded = evt.loaded
                }
            })
        }
    }

    // Prepares the data and sends the request.
    _sendRequest(file) {
        const data = new FormData()

        console.log(file)

        data.append('files', file)

        this.xhr.send(data)
    }
}

export default MyUploadAdapter