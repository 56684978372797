import React from 'react'
import Input from '../../../components/Input/Input'

const FormInputsSr = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const {title} = values

    return (
        <>
            <Input
                name="title"
                id="title"
                type="text"
                errors={errors}
                touched={touched}
                label="Naslov dokumenta"
                setFieldValue={setFieldValue}
                value={title}
            />
        </>
    )
}

export default FormInputsSr
