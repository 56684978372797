import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import Page from '../../../components/Page/Page'
import { contactService } from '../../../../services/contactService'
import ContactForm from '../ContactForm/ContactForm'
import AgencyContactsList from './AgencyContactsList'
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner'

const EditAgencyContact = () => {
    const [initialValues, setInitialValues] = useState({
        name: '',
        nameEn: '',
        phone: '',
        fax: '',
        street: '',
        city: '',
        state: '',
        stateEn: '',
        email: '',
    })
    const [isLoading, setIsLoading] = useState(false)

    const history = useHistory()

    const { id } = useParams()
    useEffect(() => {
        const getContact = async () => {
            setIsLoading(true)
            try {
                const contactData = await contactService.getContact(id)

                const { name, nameEn, phone, fax, street, city, state, stateEn, email } = contactData

                setInitialValues({
                    name,
                    nameEn,
                    phone,
                    fax,
                    street,
                    city,
                    state,
                    stateEn,
                    email
                })
                setIsLoading(false)
            } catch (e) {
                console.error(e)
            }
        }

        getContact()
    }, [id])

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')

        setIsLoading(true)

        try {
            const contact = await contactService.updateContact(values, id, 'agencija')
            setSubmitting(false)
            history.push(`/kontakti/predstavnistva-i-agencije/${contact.id}`)
            window.location.reload()
        } catch (err) {
            setStatus(err.message)
            console.error(err)
            setIsLoading(false)
        }
    }

    const onDelete = async () => {
        try {
            await contactService.deleteContact(id)
            history.push('/kontakti/predstavnistva-i-agencije')
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Page title="Kontakt Predstavništva i agencije - Izmeni unos">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <AgencyContactsList />
                    <ContactForm
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onDelete={onDelete}
                        isEditForm
                    />
                </>
            )}
        </Page>
    )
}

export default EditAgencyContact
