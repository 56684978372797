import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import Page from '../../components/Page/Page'
import HistoryForm from './HistoryForm/HistoryForm'
import HistoryList from './HistoryListList'
import { historyService } from '../../../services/historyService'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'

const EditHistory = () => {
    const [initialValues, setInitialValues] = useState({
        year: '',
        historyText: '',
        historyTextEn: '',
    })

    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)

    const { id } = useParams()
    useEffect(() => {
        const getHistoryPosts = async () => {
            try {
                setIsLoading(true)
                const historyPostData = await historyService.getHistory(id)

                const { year, historyText, historyTextEn } = historyPostData

                setInitialValues({
                    year,
                    historyText,
                    historyTextEn,
                })
                setIsLoading(false)
            } catch (e) {
                console.error(e)
            }
        }

        getHistoryPosts()
    }, [id])

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')
        setIsLoading(true)

        try {
            const historyPost = await historyService.updateHistory(values, id)
            setSubmitting(false)
            window.location.reload()
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    const onDelete = async () => {
        try {
            await historyService.deleteHistory(id)
            history.push('/istorijat')
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Page title="Vest - Izmeni unos">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <HistoryList />
                    <HistoryForm
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onDelete={onDelete}
                        isEditForm
                    />
                </>
            )}
        </Page>
    )
}

export default EditHistory
