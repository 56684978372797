import React from 'react'

import FileInput from '../../../components/FileInput/FileInput'
import MultipleFilesInput from '../../../components/MultipleFilesInput/MultipleFilesInput'
import Input from '../../../components/Input/Input'

const FormInputs = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { gallery, image, publishDate } = values

    return (
        <>
            <FileInput
                setFieldValue={setFieldValue}
                fieldValueName="image"
                initialFileId={image}
                label="Slika"
                isImage
            />
            <MultipleFilesInput
                setFieldValue={setFieldValue}
                fieldValueName="gallery"
                fileIds={gallery}
                label="Galerija"
                isGallery
            />
             <Input
                name="publishDate"
                id="publishDate"
                type="date"
                errors={errors}
                touched={touched}
                label="Datum objavljivanja"
                setFieldValue={setFieldValue}
                value={publishDate}
            />
        </>
    )
}

export default FormInputs
