import axios from 'axios'
import { storage } from './storage'
import { BASE_URL } from './constants'

class API {

    userToken = storage.get('userToken')
    lang = storage.get('currentLang')

    
    
    
    axiosInstance = axios.create({
        baseURL: BASE_URL,
        headers: {
            Accept: 'application/json',
            Authorization: `${this.userToken ? `Bearer ${this.userToken}` : ''}`,
            // 'Accept-Language': `${this.lang ? this.lang : 'en'}`
        }
    });
    
    async get(path, queryParams) {

        const res = await this.axiosInstance.get(path, { params: queryParams })

        return res.data
    }

    async post(path, body) {
        const res = await this.axiosInstance.post(path, body)

        return res.data
    }

    async put(path, body) {
        const res = await this.axiosInstance.put(path, body)

        return res.data
    }

    async delete(path) {
        const res = await this.axiosInstance.delete(path)

        return res
    }
}

export const api = new API()