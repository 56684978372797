import React from 'react'

import TextEditor from '../../../../components/TextEditor/TextEditor'

const FormInputsEn = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { textEn } = values

    return (
        <>
            <TextEditor
                data={textEn}
                setFieldValue={setFieldValue}
                label="O uslugama EN"
                name="textEn"
            />
        </>
    )
}

export default FormInputsEn
