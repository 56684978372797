import _ from 'lodash'
import Media from './Media'

class News {
    constructor(
        { 
        id, 
        title, 
        title_en, 
        slug, 
        slug_en, 
        short_text, 
        short_text_en, 
        documents, 
        documents_en, 
        text, text_en, 
        image, 
        gallery, 
        publish_date,
        youTube_video,
        youTube_video_en,
    }) {
        this.id = id
        this.title = title
        this.titleEn = title_en
        this.slug = slug
        this.slugEn = slug_en
        this.shortText = short_text
        this.shortTextEn = short_text_en
        this.documents = documents ? documents.map(document => new Media(document)) : []
        this.documentsEn = documents_en ? documents_en.map(document => new Media(document)) : []
        this.text = text
        this.textEn = text_en
        this.image = image ? new Media(image) : null
        this.gallery = gallery ? gallery.map(image => new Media(image)) : []
        this.publishDate = publish_date
        this.youTubeVideo = youTube_video ? youTube_video : ''
        this.youTubeVideoEn = youTube_video_en ? youTube_video_en : ''
    }

    get titleTruncated() {
        return _.truncate(this.title, {
            length: 40,
            omission: '...'
        })
    }
}

export default News