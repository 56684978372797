import React, { useEffect, useState } from 'react'

import { servicesService } from '../../../services/servicesService'

import SideList from '../../components/SideList/SideList'

const ServicesList = () => {
    const [servicesList, setServicesList] = useState([])

    useEffect(() => {
        const getServicesList = async () => {
            try {
                const services = await servicesService.getServices()

                setServicesList(services)
            } catch (e) {
                console.error(e)
            }
        }

        getServicesList()
    }, [])

    const onSearch = async (searchTerm) => {
        try {
            const services = await servicesService.searchServices(searchTerm)
            setServicesList(services)
        } catch (e) {
            console.error(e)
        }
    }

    return  <SideList list={servicesList} editLinkPath="/usluge" onSearch={onSearch}/>
}

export default ServicesList
