import React from 'react'

import * as yup from 'yup'
import { Formik } from 'formik'

import FormWrapper from '../../../components/FormWrapper/FormWrapper'
import FormSection from '../../../components/FormSection/FormSection'
import FormInputsEn from './FormInputsEn'
import FormInputsSr from './FormInputsSr'
import FormInputs from './FormInputs'

const ContactForm = ({ initialValues, onSubmit, onDelete, language, isEditForm = false }) => {
    const payloadSchema = yup.object().shape({
        name: yup.string().required(),
        nameEn: yup.string().required(),
        phone: yup.string(),
        fax: yup.string(),
        street: yup.string(),
        city: yup.string(),
        state: yup.string(),
        stateEn: yup.string(),
        email: yup.string(),
    })

    const _contactForm = (props) => {
        return (
            <FormSection
                {...props}
                FormInputsEn={FormInputsEn}
                FormInputsSr={FormInputsSr}
                FormInputs={FormInputs}
                isEditForm={isEditForm}
                onDelete={onDelete}
            />
        )
    }

    return (
        <FormWrapper>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={payloadSchema}
                onSubmit={onSubmit}
            >
                {_contactForm}
            </Formik>
        </FormWrapper>
    )
}

export default ContactForm
