import React from 'react'

import FileInput from '../../../components/FileInput/FileInput'

const FormInputs = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { image, document } = values

    return (
        <>
            <FileInput
                setFieldValue={setFieldValue}
                fieldValueName="image"
                initialFileId={image}
                label="Slika"
                isImage
            />
            <FileInput
                setFieldValue={setFieldValue}
                fieldValueName="document"
                initialFileId={document}
                label="Dokument"
                isDocument
            />
        </>
    )
}

export default FormInputs
