import React, { useEffect, useState } from 'react'

import { certificatesService } from '../../../services/certificatesService'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'

import Page from '../../components/Page/Page'
import SideList from '../../components/SideList/SideList'
import CertificatesForm from './CertificatesForm/CertificatesForm'

const EditCertificates = () => {
    const [initialValues, setInitialValues] = useState({
        aboutCertificates: '',
        aboutCertificatesEn: '',
        certificateDocs: [],
    })
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const getCertificates = async () => {
            setIsLoading(true)
            try {
                const certificatesData = await certificatesService.getCertificates()

                const { aboutCertificates, aboutCertificatesEn, certificateDocs } = certificatesData

                setInitialValues({
                    aboutCertificates,
                    aboutCertificatesEn,
                    certificateDocs,
                })
                setIsLoading(false)
            } catch (e) {
                console.error(e)
            }
        }

        getCertificates()
    }, [])

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')
        setIsLoading(true)

        try {
            await certificatesService.updateCertificates(values)

            window.location.reload()

            setSubmitting(false)
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    return (
        <Page title="Sertifikati">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <SideList list={[]} editLinkPath="" onSearch={() => {}} />
                    <CertificatesForm initialValues={initialValues} onSubmit={onSubmit} />
                </>
            )}
        </Page>
    )
}

export default EditCertificates
