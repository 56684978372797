import React from 'react'
import { Link } from 'react-router-dom'

import styles from './SideListItem.module.css'

const SideListItem = ({ item, path, showImage }) => {
    const { slug, titleTruncated, imageUrl } = item

    return (
        <Link to={`${path}/${slug}`}>
            {showImage && (
                <div className={styles.image}>
                    <img src={imageUrl} className={styles.img} alt={titleTruncated} />
                </div>
            )}
            <div className={styles.item}>
                <h3 className={styles.title}>{titleTruncated}</h3>
            </div>
        </Link>
    )
}

export default SideListItem
