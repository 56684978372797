import React from 'react'

import Input from '../../../components/Input/Input'
import TextEditor from '../../../components/TextEditor/TextEditor'

const FormInputsEn = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { serviceNameEn,aboutServiceHomeEn, aboutServiceEn, aboutServiceShortEn } = values

    return (
        <>
         <Input
                name="serviceNameEn"
                id="serviceNameEn"
                type="text"
                errors={errors}
                touched={touched}
                label="Naziv"
                setFieldValue={setFieldValue}
                value={serviceNameEn}
            />
            <TextEditor
                data={aboutServiceHomeEn}
                setFieldValue={setFieldValue}
                label="Tekst za naslovnu stranu EN"
                name="aboutServiceHomeEn"
            />
            <TextEditor
                data={aboutServiceShortEn}
                setFieldValue={setFieldValue}
                label="Kratak tekst"
                name="aboutServiceShortEn"
            />
            <TextEditor
                data={aboutServiceEn}
                setFieldValue={setFieldValue}
                label="Tekst"
                name="aboutServiceEn"
            />
        </>
    )
}

export default FormInputsEn
