import React, { useState } from 'react'

import Page from '../../components/Page/Page'
import { ourNumbersService } from '../../../services/ourNumbersService'
import OurNumbersList from './OurNumbersList'
import OurNumbersForm from './OurNumbersForm/OurNumbersForm'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'

const AddOurNumber = () => {
    const initialValues = {
        number: '',
        name: '',
        nameEn: '',
        description: '',
        descriptionEn: '',
    }
    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')
        setIsLoading(true)
        try {
            await ourNumbersService.postOurNumber(values)

            setSubmitting(false)

            window.location.reload()
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    return (
        <Page title="Naše brojke - Novi unos">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <OurNumbersList />
                    <OurNumbersForm initialValues={initialValues} onSubmit={onSubmit} />
                </>
            )}
        </Page>
    )
}

export default AddOurNumber
