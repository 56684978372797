import React from 'react'

import TextEditor from '../../../components/TextEditor/TextEditor'

const FormInputsSr = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { aboutShort, ourFleet } = values

    return (
        <>
            <TextEditor
                data={aboutShort}
                setFieldValue={setFieldValue}
                label="O nama"
                name="aboutShort"
            />
            <TextEditor
                data={ourFleet}
                setFieldValue={setFieldValue}
                label="Naša flota - naslovna"
                name="ourFleet"
            />
        </>
    )
}

export default FormInputsSr
