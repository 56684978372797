import React, { useState, useEffect } from 'react'

import styles from './FileInput.module.css'
import { fileService } from '../../../services/fileService'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'

const FileInput = ({
    setFieldValue,
    fieldValueName,
    initialFileId,
    label,
    isImage = false,
    isDocument = false,
    isVideo = false,
}) => {
    const [file, setFile] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const fetchInitialFile = async (fileId) => {
            try {
                setIsLoading(true)
                const file = await fileService.getSingleFile(fileId)
                setIsLoading(false)
                setFile(file)
            } catch (e) {
                console.error(e)
            }
        }

        initialFileId ? fetchInitialFile(initialFileId) : setFile('')
    }, [initialFileId])

    const onUploadSingleFile = async (e) => {
        try {
            setIsLoading(true)
            const fileInput = e.target

            const file = fileInput.files[0]

            const uploadedFile = await fileService.uploadSingleFile(file)

            setFile(uploadedFile)

            setIsLoading(false)

            setFieldValue(fieldValueName, uploadedFile.id)
        } catch (error) {
            console.error(error)
        }
    }

    const onRemoveFile = async () => {
        await fileService.deleteSingleFile(file.id)
        setFieldValue(fieldValueName, '')
        setFile(null)
    }

    return (
        <div className={styles.fileInputContainer}>
            <div className={styles.fileInputWrapper}>
                <label className={styles.label} htmlFor="file">
                    {label}:
                </label>
                <label className={styles.fileInput}>
                    <input
                        type="file"
                        aria-label="File browser"
                        name="photo"
                        onChange={onUploadSingleFile}
                        className={styles.input}
                    />
                    <span className={styles.fileCustom}>Choose</span>
                </label>
            </div>
            {isLoading && <LoadingSpinner />}
            {file && (
                <div
                    className={`${styles.filePreviewWrapper} ${
                        isDocument && styles.documentPreviewWrapper
                    }`}
                >
                    <div
                        className={`${styles.filePreview} ${isDocument && styles.documentPreview}`}
                    >
                        <div className={styles.filePreviewRemove} onClick={onRemoveFile}>
                            <i className="fas fa-times"></i>
                        </div>
                        {isImage && <img src={file.url} alt="Todo" />}
                        {isVideo && (
                            <video width="100%" className={styles.videoPreview} controls>
                                <source src={file.url} type="video/mp4" />
                                <source src={file.url} type="video/ogg" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                        {isDocument && (
                            <a
                                href={file.url}
                                className={styles.document}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="far fa-file-alt"></i>
                                <span>
                                    {file.name}
                                    {file.ext}
                                </span>
                            </a>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default FileInput
