import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { certificateDocsService } from '../../../services/certificateDocsService'

import Page from '../../components/Page/Page'
import CertificateDocsList from './CertificateDocsList'
import CertificateDocsForm from './CertificateDocsForm/CertificateDocsForm'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'

const EditCertificateDoc = () => {
    const [id, setId] = useState('')
    const [initialValues, setInitialValues] = useState({
        title: '',
        titleEn: '',
        image: '',
        document: '',
    })

    const { slug } = useParams()
    const history = useHistory()

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const getCertificateDoc = async () => {
            setIsLoading(true)
            try {
                const certificateDoc = await certificateDocsService.getCertificateDoc(slug)

                const { id, title, titleEn, image, document } = certificateDoc

                setInitialValues({
                    title,
                    titleEn,
                    image: image && image.id,
                    document: document && document.id,
                })
                setIsLoading(false)
                setId(id)
            } catch (e) {
                console.error(e)
            }
        }

        getCertificateDoc()
    }, [slug])

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')
        setIsLoading(true)

        try {
            const certificateDoc = await certificateDocsService.updateCertificateDoc(values, id)

            setSubmitting(false)

            history.push(`/sertifikati/sertifikati-dokumenta/${certificateDoc.slug}`)

            window.location.reload()
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    const onDelete = async () => {
        try {
            await certificateDocsService.deleteCertificateDoc(id)

            history.push('/sertifikati/sertifikati-dokumenta')
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Page title="Sertifikati - Izmena unosa">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <CertificateDocsList />
                    <CertificateDocsForm
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onDelete={onDelete}
                        isEditForm
                    />
                </>
            )}
        </Page>
    )
}

export default EditCertificateDoc
