class History {
    constructor({ id, year, history_text, history_text_en }) {
        this.year = year
        this.historyText = history_text
        this.historyTextEn = history_text_en
        this.titleTruncated = year
        this.slug = id


    }
}

export default History