import React from 'react'
import Input from '../../../components/Input/Input'

const FormInputsEn = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { nameEn, stateEn } = values

    return (
        <>
            <Input
                name="nameEn"
                id="nameEn"
                type="text"
                errors={errors}
                touched={touched}
                label="Naziv En"
                setFieldValue={setFieldValue}
                value={nameEn}
            />
            <Input
                name="stateEn"
                id="stateEn"
                type="text"
                errors={errors}
                touched={touched}
                label="Država En"
                setFieldValue={setFieldValue}
                value={stateEn}
            />
        </>
    )
}

export default FormInputsEn
