import React from 'react'

import Input from '../../../components/Input/Input'
import TextEditor from '../../../components/TextEditor/TextEditor'

const FormInputsEn = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { titleEn, subtitleEn } = values

    return (
        <>
            <Input
                name="titleEn"
                id="titleEn"
                type="text"
                errors={errors}
                touched={touched}
                label="Naslov En"
                setFieldValue={setFieldValue}
                value={titleEn}
            />
            <Input
                name="subtitleEn"
                id="subtitleEn"
                type="text"
                errors={errors}
                touched={touched}
                label="Podnaslov En"
                setFieldValue={setFieldValue}
                value={subtitleEn}
            />
        </>
    )
}

export default FormInputsEn
