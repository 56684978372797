import React, { useEffect, useState } from 'react'
import { servicesService } from '../../../../services/servicesService'
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner'

import Page from '../../../components/Page/Page'
import SideList from '../../../components/SideList/SideList'
import AboutServicesForm from './AboutServicesForm/AboutServicesForm'

const EditAboutServices = () => {
    const [initialValues, setInitialValues] = useState({
        text: '',
        textEn: '',
    })

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const getAbout = async () => {
            try {
                setIsLoading(true)
                const aboutData = await servicesService.getAboutServices()

                const { text, textEn } = aboutData

                setInitialValues({
                    text: text,
                    textEn: textEn,
                })
                setIsLoading(false)
            } catch (e) {
                console.error(e)
            }
        }

        getAbout()
    }, [])

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')
        setIsLoading(true)

        try {
            await servicesService.updateAboutServices(values)

            window.location.reload()

            setSubmitting(false)
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    return (
        <Page title="O uslugama">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <SideList list={[]} editLinkPath="" onSearch={() => {}} />
                    <AboutServicesForm initialValues={initialValues} onSubmit={onSubmit} />
                </>
            )}
        </Page>
    )
}

export default EditAboutServices
