import { api } from '../shared/api'
import Service from '../models/Service'
import AboutServices from '../models/AboutServices'

class ServicesService {


    async getAboutServices() {
        const path = '/service-page'

        const aboutServices = await api.get(path)

        return new AboutServices(aboutServices)
    }


    async updateAboutServices(data) {
        const path = '/service-page'

        const {
            text,
            textEn,
        } = data

        const payload = {
            text: text,
            text_en: textEn,
        }

        const updatedAboutServices = await api.put(path, payload)

        return new AboutServices(updatedAboutServices)
    }

    async getServices() {
        const path = '/services'

        const services = await api.get(path)

        return services.map(service => new Service(service))
    }

    async searchServices(searchTerm) {
        const path = '/services'

        const queryParams = {
            _sort: 'createdAt:DESC',
            service_name_contains: searchTerm
        }

        const services = await api.get(path, queryParams)

        return services.map(service => new Service(service))
    }

    async getService(slug) {
        const path = `/services?slug=${slug}`

        const services = await api.get(path)

        return new Service(services[0])

    }

    async updateService(data, id) {
        const path = `/services/${id}`

        const {
            gallery,
            serviceName,
            serviceNameEn,
            aboutService,
            aboutServiceHome,
            aboutServiceHomeEn,
            aboutServiceEn,
            aboutServiceShort,
            aboutServiceShortEn,
            image
        } = data

        const payload = {
            gallery,
            service_name: serviceName,
            service_name_en: serviceNameEn,
            about_service: aboutService,
            about_service_home: aboutServiceHome,
            about_service_home_en: aboutServiceHomeEn,
            about_service_en: aboutServiceEn,
            about_service_short: aboutServiceShort,
            about_service_short_en: aboutServiceShortEn,
            image
        }

        const updatedService = await api.put(path, payload)

        return new Service(updatedService)
    }
}


export const servicesService = new ServicesService()