import React from 'react'
import { Field } from 'formik'

import styles from './LoginInput.module.css'

const LoginInput = ({ name, id, value, type, errors, touched, label }) => {

    const isInvalid = errors && errors[name] && touched && touched[name]
    
    return (
        <div className={styles.loginInput}>
            <Field>
                {({ field }) => (
                    <input
                        id={id}
                        type={type}
                        name={name}
                        className={`${styles.input} ${touched[name] && styles.active} ${isInvalid && styles.invalid}`}
                        autoComplete={'off'}
                        {...field}
                        value={value}
                    />
                )}
            </Field>
            <label htmlFor={id} className={styles.label}>
                {label}
            </label>
            <span className={styles.errorMsg}>{isInvalid ? `* ${errors[name]}` : ''}</span>
        </div>
    )
}

export default LoginInput
