import React from 'react'

import TextEditor from '../../../../components/TextEditor/TextEditor'

const FormInputsSr = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { text } = values

    return (
        <>
            <TextEditor
                data={text}
                setFieldValue={setFieldValue}
                label="O uslugama"
                name="text"
            />
        </>
    )
}

export default FormInputsSr
