import React, { useEffect, useState } from 'react'

import SideList from '../../components/SideList/SideList'
import { galleryService } from '../../../services/galleryService'

const LIMIT = 30

const GalleryList = () => {
    const [galleryList, setGalleryList] = useState([])
    const [start, setStart] = useState(0)
    const [hasMore, setHasMore] = useState(false)

    useEffect(() => {
        const getGalleryItems = async () => {
            try {
                const response = await galleryService.getGalleryItems(0, LIMIT)
                const { total, posts } = response

                setGalleryList(posts)
                setHasMore(total > posts.length)
                setStart(LIMIT)
            } catch (e) {
                console.error(e)
            }
        }
        getGalleryItems()
    }, [])

    const onLoadMore = async () => {
        try {
            const response = await galleryList.getGalleryItems(start, LIMIT)
            const { total, posts } = response

            setGalleryList((prevState) => [...prevState, ...posts])
            setStart(start + LIMIT)
            setHasMore(total > [...galleryList, ...posts].length)
        } catch (e) {
            console.error(e)
        }
    }

    const onSearch = async (searchTerm) => {
        try {
            const galleryItems = await galleryService.searchGalleryItem(searchTerm)
            setGalleryList(galleryItems)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <SideList
                list={galleryList}
                editLinkPath="/galerija"
                onSearch={onSearch}
                withLoadMoreButton={hasMore}
                onLoadMore={onLoadMore}
                showImage
            />
        </>
    )
}

export default GalleryList
