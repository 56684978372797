import React from 'react'

import { Formik, Form } from 'formik'
import * as yup from 'yup'

import styles from './ForgotPassword.module.css'
import Input from '../components/Input/Input'
import { userService } from '../../services/userService'

const ForgotPassword = () => {
    const payloadSchema = yup.object().shape({
        email: yup.string().email().required(),
    })

    const initialValues = {
        email: '',
    }

    const onSubmit = async (values, { setStatus, setSubmitting }) => {
        setStatus('')

        try {
            const { email } = values
            const user = await userService.forgotPassword(email)

            console.log(values)
            console.log(user)

            setSubmitting(false)
        } catch (err) {
            setStatus(err.response)
            console.log(err)
        }
    }

    const _forgotPasswordForm = ({
        errors,
        status,
        touched,
        isSubmitting,
        values,
        setFieldValue,
    }) => {
        const { email } = values
        return (
            <Form>
                <div className={styles.loginInputs}>
                    <Input
                        name="email"
                        id="email"
                        type="email"
                        errors={errors}
                        touched={touched}
                        label="Email"
                        setFieldValue={setFieldValue}
                        value={email}
                    />
                    <div className={styles.errMsg}>{status ? `${status}!` : ''}</div>
                </div>
                <button type="submit" className={styles.button}>
                    Send
                </button>
            </Form>
        )
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={payloadSchema}
            onSubmit={onSubmit}
        >
            {_forgotPasswordForm}
        </Formik>
    )
}

export default ForgotPassword
