import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import Page from '../../components/Page/Page'
import { publicCallsService } from '../../../services/publicCallsService'
import PublicCallsList from './PublicCallsList'
import PublicCallForm from './PublicCallForm/AdvertisementForm'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'

const EditPublicCalls = () => {
    const [id, setId] = useState('')
    const [initialValues, setInitialValues] = useState({
        title: '',
        titleEn: '',
        text: '',
        textEn: '',
        expDate: '',
    })

    const { slug } = useParams()
    const history = useHistory()

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const getPublicCall = async () => {
            try {
                setIsLoading(true)
                const publicCallData = await publicCallsService.getSinglePublicCall(slug)

                const { id, title, titleEn, text, textEn, expDate } = publicCallData

                setInitialValues({
                    title,
                    titleEn,
                    text,
                    textEn,
                    expDate,
                })

                setIsLoading(false)

                setId(id)
            } catch (e) {
                console.error(e)
            }
        }

        getPublicCall()
    }, [slug])

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')
        setIsLoading(true)

        try {
            const publicCall = await publicCallsService.updatePublicCall(values, id)

            setSubmitting(false)

            history.push(`/javni-pozivi/${publicCall.slug}`)

            window.location.reload()
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    const onDelete = async () => {
        try {
            await publicCallsService.deletePublicCall(id)

            history.push('/javni-pozivi')
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Page title="JavniPozivi - Izmeni unos">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <PublicCallsList />
                    <PublicCallForm
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onDelete={onDelete}
                        isEditForm
                    />
                </>
            )}
        </Page>
    )
}

export default EditPublicCalls
