import React from 'react'

import styles from './LogIn.module.css'
import LogInForm from './LogInForm/LogInForm'

const LogIn = () => {
    return (
        <div className={styles.login}>
            <div className={styles.loginBox}>
                <div className={styles.titleContainer}>
                    <div className={styles.mainTitle}>
                        <div className={styles.logo}>
                            <img src="images/logo-bit.png" alt="logo" />
                        </div>
                        <h2>Welcome to BIT cms</h2>
                    </div>
                    <h3 className={styles.logInTitle}>Log in</h3>
                </div>
                <LogInForm />
            </div>
        </div>
    )
}

export default LogIn
