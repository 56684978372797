import { api } from '../shared/api'
import About from '../models/About'

class AboutService {


    async getAbout() {
        const path = '/about'

        const about = await api.get(path)

        return new About(about)
    }


    async updateAbout(data) {
        const path = '/about'

        const {
            aboutShort,
            aboutShortEn,
            ourFleet,
            ourFleetEn
        } = data

        const payload = {
            about_short: aboutShort,
            about_short_en: aboutShortEn,
            our_fleet: ourFleet,
            our_fleet_en: ourFleetEn,
        }

        const updatedAbout = await api.put(path, payload)

        return new About(updatedAbout)
    }
}

export const aboutService = new AboutService()