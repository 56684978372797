import { api } from '../shared/api'
import Media from '../models/Media'

class FileService {

    async uploadSingleFile(file) {
        const path = '/upload'

        const formData = new FormData()
        formData.append('files', file, file.name)

        const uploadedFile = await api.post(path, formData)

        return new Media(uploadedFile[0])
    }

    async uploadMultipleFiles(files) {
        const path = '/upload'

        const formData = new FormData()

        files.map(file => {
           return formData.append('files', file)
        })

        const uploadedFiles = await api.post(path, formData)


        return uploadedFiles.map(file => new Media(file))
    }

    async getSingleFile(fileId) {
        const path = `/upload/files/${fileId}`

        const file = await api.get(path)

        return new Media(file)
    }

    async deleteSingleFile(fileId) {

        const path = `/upload/files/${fileId}`

        await api.delete(path)
    }
}

export const fileService = new FileService()