import React from 'react'

import Input from '../../../components/Input/Input'

const FormInputs = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { number } = values

    return (
        <>
            <Input
                name="number"
                id="number"
                type="text"
                errors={errors}
                touched={touched}
                label="Broj"
                setFieldValue={setFieldValue}
                value={number}
            />
        </>
    )
}

export default FormInputs
