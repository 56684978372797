import React, { useState } from 'react'
import Modal from 'react-modal'

import './FullScreen.css'
import TextEditor from '../TextEditor'

Modal.setAppElement('#modal')

const FullScreen = ({ data, label, setFieldValue, name }) => {
    const [modalIsOpen, setIsOpen] = useState(false)

    const openModal = () => {
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false)
    }
    return (
        <div className="fullscreen-editor">
            <button type="button" className="open-modal" onClick={openModal}>
                <i className="fas fa-expand"></i>
            </button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                className="Modal"
                overlayClassName="Overlay"
            >
                <div className="close-wrapper">
                    <button type="button" onClick={closeModal} className="close-modal">
                        <i className="fas fa-times"></i>
                    </button>
                </div>
                <TextEditor
                    data={data}
                    setFieldValue={setFieldValue}
                    label={label}
                    name={name}
                    isModalOpen={modalIsOpen}
                />
            </Modal>
        </div>
    )
}

export default FullScreen
