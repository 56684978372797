import React from 'react'

import styles from './FormWrapper.module.css'

const FormWrapper = ({children}) => {
    return <section className={styles.formWrapper} id="formWrapper">
        {children}
    </section>
}

export default FormWrapper
