import React, { useState } from 'react'

import styles from './DeleteButton.module.css'
import FormButton from '../FormButton/FormButton'

const DeleteButton = ({ onDelete }) => {
    const [showConfirmation, setShowConfirmation] = useState(false)

    const onDeleteClick = () => {
        setShowConfirmation(true)

        const body = document.querySelector('body')
        body.classList.add(styles.lockScroll)
    }

    const onConfirmDelete = () => {
        onDelete()
        setShowConfirmation(false)

        const body = document.querySelector('body')
        body.classList.remove(styles.lockScroll)

    }

    const onCancel = () => {
        setShowConfirmation(false)
        const body = document.querySelector('body')
        body.classList.remove(styles.lockScroll)
    }

    return (
        <div className={styles.buttonsContainer}>
            {/* <FormButton type="submit" text="Submit" /> */}
            <FormButton type="button" text="Delete" onClick={onDeleteClick} isRed />
            {showConfirmation && (
                <div className={styles.confirmationOverlay}>
                    <div className={styles.confirmation}>
                        <p className={styles.text}>
                            Click CONFIRM if you want to delete this entry
                        </p>
                        <div className={styles.confirmButtons}>
                            <FormButton type="button" text="Confirm" onClick={onConfirmDelete} />
                            <FormButton type="button" text="Cancel" onClick={onCancel} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DeleteButton
