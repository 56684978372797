import React, { useEffect, useState } from 'react'

import SideList from '../../components/SideList/SideList'
import { newsService } from '../../../services/newsService'

const LIMIT = 30

const NewsList = () => {
    const [newsList, setNewsList] = useState([])
    const [start, setStart] = useState(0)
    const [hasMore, setHasMore] = useState(false)

    useEffect(() => {
        const getNewsPosts = async () => {
            try {
                const response = await newsService.getNewsPosts(0, LIMIT)
                const { total, posts } = response

                setNewsList(posts) 
                setHasMore(total > posts.length)
                setStart(LIMIT)

            } catch (e) {
                console.error(e)
            }
        }
        getNewsPosts()
    }, [])

    const onLoadMore = async () => {
        try {
            const response = await newsService.getNewsPosts(start, LIMIT)
            const { total, posts } = response

            setNewsList((prevState) => [...prevState, ...posts])
            setStart(start + LIMIT)
            setHasMore(total > [...newsList, ...posts].length)
        } catch (e) {
            console.error(e)
        }
    }

    const onSearch = async (searchTerm) => {
        try {
            const newsPosts = await newsService.searchNews(searchTerm)
            setNewsList(newsPosts)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <SideList list={newsList} editLinkPath="/vesti" onSearch={onSearch} withLoadMoreButton={hasMore} onLoadMore={onLoadMore}/>
        </>
    )
}

export default NewsList
