import React, { useState, useEffect } from 'react'

import styles from './MultipleFilesInput.module.css'
import { fileService } from '../../../services/fileService'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'

const MultipleFilesInput = ({
    setFieldValue,
    fieldValueName,
    fileIds,
    label,
    isGallery = false,
    isDocuments = false,
    isVideos = false,
}) => {
    const [filesList, setFilesList] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const fetchInitialFile = async (fileId) => {
            try {
                setIsLoading(true)
                const file = await fileService.getSingleFile(fileId)
                setIsLoading(false)

                return file
            } catch (e) {
                console.error(e)
            }
        }

        const uploadedFiles =
            fileIds &&
            fileIds.map((fileId) => {
                return fetchInitialFile(fileId)
            })

        uploadedFiles &&
            Promise.all(uploadedFiles).then((value) => {
                setFilesList(value)
            })
    }, [fileIds])

    const onUploadFiles = async (e) => {
        try {
            setIsLoading(true)
            const fileInput = e.target

            const files = fileInput.files

            const uploadedFiles = await fileService.uploadMultipleFiles([...files])

            const filesIdsList = uploadedFiles.map((file) => file.id)

            setIsLoading(false)

            setFieldValue(fieldValueName, [...fileIds, ...filesIdsList])
        } catch (error) {
            console.error(error)
        }
    }

    const onRemoveFile = async (fileId) => {
        try {
            // await fileService.deleteSingleFile(fileId)

            const newFilesList = filesList.filter((file) => file.id !== fileId)
            const newFileIds = newFilesList.map((file) => file.id)

            setFieldValue(fieldValueName, newFileIds)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div className={styles.fileInputContainer}>
            <div className={styles.fileInputWrapper}>
                <label className={styles.label} htmlFor="file">
                    {label}:
                </label>
                <label className={styles.fileInput}>
                    <input
                        type="file"
                        aria-label="File browser"
                        name="photos"
                        multiple
                        onChange={onUploadFiles}
                        className={styles.input}
                    />
                    <span className={styles.fileCustom}>Choose</span>
                </label>
            </div>
            {isLoading && <LoadingSpinner />}
            {filesList.length > 0 && (
                <div
                    className={`${styles.filePreviewWrapper} ${
                        isDocuments && styles.documentsPreviewWrapper
                    }`}
                >
                    {filesList.map((file) => {
                        const { id, url, name, ext } = file
                        return (
                            <div
                                className={`${styles.filePreview} ${
                                    isDocuments && styles.documentsPreview
                                }`}
                                key={id}
                            >
                                <div
                                    className={styles.filePreviewRemove}
                                    onClick={() => {
                                        onRemoveFile(id)
                                    }}
                                >
                                    <i className="fas fa-times"></i>
                                </div>
                                {isGallery && <img src={url} alt="Todo" />}
                                {isVideos && (
                                    <video width="100%" className={styles.videoPreview} controls>
                                        <source src={url} type="video/mp4" />
                                        <source src={url} type="video/ogg" />
                                        Your browser does not support the video tag.
                                    </video>
                                )}
                                {isDocuments && (
                                    <a
                                        href={url}
                                        className={styles.document}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="far fa-file-alt"></i>
                                        <span>
                                            {name}
                                            {ext}
                                        </span>
                                    </a>
                                )}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default MultipleFilesInput
