
class Contact {
    constructor({ id, name, name_en, phone, category, fax, street, city, state, state_en, email }) {
        this.id = id
        this.name = name
        this.nameEn = name_en
        this.phone = phone
        this.category = category
        this.fax = fax
        this.street = street
        this.city = city
        this.state = state
        this.stateEn = state_en ? state_en : ''
        this.titleTruncated = name
        this.slug = id
        this.email = email

    }
}

export default Contact