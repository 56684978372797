import { api } from '../shared/api'
import GalleryItem from '../models/GalleryItem'


class GalleryService {

    async getGalleryItems(startParam, limitParam) {
        const path = `/galleries?_start=${startParam}&_limit=${limitParam}`

        const queryParams = {
            _sort: 'createdAt:DESC',
        }


        const response = await api.get(path, queryParams)


        const { total, start, limit, posts } = response

        return {
            total,
            start,
            limit,
            posts: posts.map(galleryItem => new GalleryItem(galleryItem))
        }

    }

    async postGalleryItem(data) {
        const path = '/galleries'

        const {
            title,
            titleEn,
            subtitle,
            subtitleEn,
            category,
            image,
        } = data

        const payload = {
            title,
            title_en: titleEn,
            subtitle,
            subtitle_en: subtitleEn,
            image,
            category
        }

        const galleryItem = await api.post(path, payload)

        return new GalleryItem(galleryItem)
    }

    async getGalleryItem(id) {
        const path = `/galleries/${id}`

        const galleryItem = await api.get(path)

        return new GalleryItem(galleryItem)

    }

    async updateGalleryItem(data, id) {
        const path = `/galleries/${id}`

        const {
            title,
            titleEn,
            subtitle,
            subtitleEn,
            image,
            category
        } = data

        const payload = {
            title,
            title_en: titleEn,
            subtitle,
            subtitle_en: subtitleEn,
            image,
            category
        }

        const galleryItem = await api.put(path, payload)

        return new GalleryItem(galleryItem)
    }

    async deleteGalleryItem(id) {
        const path = `/galleries/${id}`

        await api.delete(path)

    }

    async searchGalleryItem(searchTerm) {
        const path = '/galleries'

        const queryParams = {
            _sort: 'createdAt:DESC',
            title_contains: searchTerm
        }

        const { posts } = await api.get(path, queryParams)

        return posts.map(galleryItem => new GalleryItem(galleryItem))
    }
}

export const galleryService = new GalleryService()