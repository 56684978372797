import _ from 'lodash'

class PublicCall {
    constructor(
        {
            id,
            title,
            title_en,
            slug,
            slug_en,
            text,
            text_en,
            exp_date,

        }) {
        this.id = id
        this.title = title
        this.titleEn = title_en
        this.slug = slug
        this.slugEn = slug_en
        this.text = text
        this.textEn = text_en
        this.expDate = exp_date

    }

    get titleTruncated() {
        return _.truncate(this.title, {
            length: 40,
            omission: '...'
        })
    }
}

export default PublicCall