import React from 'react'

import * as yup from 'yup'
import { Formik } from 'formik'

import FormWrapper from '../../../components/FormWrapper/FormWrapper'
import FormSection from '../../../components/FormSection/FormSection'
import FormInputsEn from './FormInputsEn'
import FormInputsSr from './FormInputsSr'
import FormInputs from './FormInputs'

const NewsForm = ({ initialValues, onSubmit, onDelete, language, isEditForm = false }) => {
    const payloadSchema = yup.object().shape({
        title: yup.string().required(),
        titleEn: yup.string().required(),
        shortText: yup.string(),
        shortTextEn: yup.string(),
        text: yup.string(),
        textEn: yup.string(),
        gallery: yup.array().of(yup.string()),
        image: yup.string().nullable(),
        publishDate: yup.string(),
        documents: yup.array().of(yup.string()),
        documentsEn: yup.array().of(yup.string()),
        youTubeVideo: yup.string(),
        youTubeVideoEn: yup.string(),
    })

    const _newsForm = (props) => {
        return (
            <FormSection
                {...props}
                FormInputsEn={FormInputsEn}
                FormInputsSr={FormInputsSr}
                FormInputs={FormInputs}
                isEditForm={isEditForm}
                onDelete={onDelete}
            />
        )
    }

    return (
        <FormWrapper>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={payloadSchema}
                onSubmit={onSubmit}
            >
                {_newsForm}
            </Formik>
        </FormWrapper>
    )
}

export default NewsForm
