import { api } from '../shared/api'
import News from '../models/News'


class NewsService {

    async getNewsPosts(startParam, limitParam) {
        const path = `/news-posts?_start=${startParam}&_limit=${limitParam}`

        const queryParams = {
            _sort: 'publish_date:DESC',
        }

        const response = await api.get(path, queryParams)

        const { total, start, limit, posts } = response

        return {
            total,
            start,
            limit,
            posts: posts.map(news => new News(news))
        }

    }

    async postNews(data) {
        const path = '/news-posts'

        const {
            title,
            titleEn,
            shortText,
            shortTextEn,
            documents,
            documentsEn,
            text,
            textEn,
            gallery,
            image,
            publishDate,
            youTubeVideo,
            youTubeVideoEn
        } = data

        const payload = {
            title,
            title_en: titleEn,
            short_text: shortText,
            short_text_en: shortTextEn,
            documents,
            documents_en: documentsEn,
            text,
            text_en: textEn,
            gallery,
            image,
            publish_date: publishDate,
            youTube_video: youTubeVideo,
            youTube_video_en: youTubeVideoEn
        }

        const news = await api.post(path, payload)

        return new News(news)
    }

    async getSingleNews(slug) {
        const path = `/news-posts?slug=${slug}`

        const response = await api.get(path)

        const { total, start, limit, posts } = response


        return new News(posts[0])

    }

    async updateNews(data, id) {
        const path = `/news-posts/${id}`

        const {
            title,
            titleEn,
            shortText,
            shortTextEn,
            documents,
            documentsEn,
            text,
            textEn,
            gallery,
            image,
            publishDate,
            youTubeVideo,
            youTubeVideoEn
        } = data

        const payload = {
            title,
            title_en: titleEn,
            short_text: shortText,
            short_text_en: shortTextEn,
            documents,
            documents_en: documentsEn,
            text,
            text_en: textEn,
            gallery,
            image,
            publish_date: publishDate,
            youTube_video: youTubeVideo,
            youTube_video_en: youTubeVideoEn
        }

        const news = await api.put(path, payload)

        return new News(news)
    }

    async deleteNews(id) {
        const path = `/news-posts/${id}`

        await api.delete(path)

    }

    async searchNews(searchTerm) {
        const path = '/news-posts'

        const queryParams = {
            _sort: 'createdAt:DESC',
            title_contains: searchTerm
        }

        const {posts} = await api.get(path, queryParams)

        return posts.map(news => new News(news))
    }
}

export const newsService = new NewsService()