import React from 'react'

import Input from '../../../components/Input/Input'

const FormInputsSr = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { name, description } = values

    return (
        <>
            <Input
                name="name"
                id="name"
                type="text"
                errors={errors}
                touched={touched}
                label="Naslov "
                setFieldValue={setFieldValue}
                value={name}
            />
            <Input
                name="description"
                id="description"
                type="text"
                errors={errors}
                touched={touched}
                label="Kratak opis "
                setFieldValue={setFieldValue}
                value={description}
            />
        </>
    )
}

export default FormInputsSr
