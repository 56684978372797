import React from 'react'

import Input from '../../../components/Input/Input'
import TextEditor from '../../../components/TextEditor/TextEditor'
import MultipleFilesInput from '../../../components/MultipleFilesInput/MultipleFilesInput'
import YouTubeVideo from '../../../components/YouTubeVideo/YouTubeVideo'

const FormInputsSr = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { title, subtitle } = values

    return (
        <>
            <Input
                name="title"
                id="title"
                type="text"
                errors={errors}
                touched={touched}
                label="Naslov"
                setFieldValue={setFieldValue}
                value={title}
            />
            <Input
                name="subtitle"
                id="subtitle"
                type="text"
                errors={errors}
                touched={touched}
                label="Podnaslov"
                setFieldValue={setFieldValue}
                value={subtitle}
            />
        </>
    )
}

export default FormInputsSr
