import React from 'react'

import Input from '../../../components/Input/Input'
import TextEditor from '../../../components/TextEditor/TextEditor'
import MultipleFilesInput from '../../../components/MultipleFilesInput/MultipleFilesInput'
import YouTubeVideo from '../../../components/YouTubeVideo/YouTubeVideo'

const FormInputsEn = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { titleEn, shortTextEn, textEn, documentsEn, youTubeVideoEn} = values

    return (
        <>
         <Input
                name="titleEn"
                id="titleEn"
                type="text"
                errors={errors}
                touched={touched}
                label="Naslov En"
                setFieldValue={setFieldValue}
                value={titleEn}
            />
            <TextEditor
                data={shortTextEn}
                setFieldValue={setFieldValue}
                label="Kratak tekst En"
                name="shortTextEn"
            />
            <TextEditor
                data={textEn}
                setFieldValue={setFieldValue}
                label="Tekst En"
                name="textEn"
            />
            <MultipleFilesInput
                setFieldValue={setFieldValue}
                fieldValueName="documentsEn"
                fileIds={documentsEn}
                label="Dokumenta En"
                isDocuments
            />
             <YouTubeVideo
                name="youTubeVideoEn"
                id="youTubeVideoEn"
                label="YouTube video En"
                errors={errors}
                touched={touched}
                value={youTubeVideoEn}
            />
        </>
    )
}

export default FormInputsEn
