import React from 'react'

import * as yup from 'yup'
import { Formik } from 'formik'
import FormSection from '../../../components/FormSection/FormSection'
import FormInputsEn from './FormInputsEn'
import FormInputsSr from './FormInputsSr'
import FormWrapper from '../../../components/FormWrapper/FormWrapper'

const CertificatesForm = ({ initialValues, onSubmit, onDelete, language, isEditForm = false }) => {
    const payloadSchema = yup.object().shape({
        aboutCertificates: yup.string(),
        aboutCertificatesEn: yup.string(),
        certificateDocs: yup.array(),
    })

    const _certificatesForm = (props) => {
        return (
            <FormSection
                {...props}
                FormInputsEn={FormInputsEn}
                FormInputsSr={FormInputsSr}
                FormInputs={() => <></>}
                isEditForm={isEditForm}
                onDelete={onDelete}
            />
        )
    }

    return (
        <FormWrapper>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={payloadSchema}
                onSubmit={onSubmit}
            >
                {_certificatesForm}
            </Formik>
        </FormWrapper>
    )
}

export default CertificatesForm
