import React from 'react'

import styles from './SelectOptionsDropdown.module.css'

const SelectOptionsDropdown = ({
    selectDropdownList = [],
    setFieldValue,
    fieldValueName,
    label,
    value,
}) => {
    const onChange = (e) => {
        setFieldValue(fieldValueName, e.target.value)
    }
    return (
        <div className={styles.selectOptions}>
            {selectDropdownList.length > 0 && (
                <>
                    <label htmlFor="selectOptions" className={styles.label}>
                        {label}:{' '}
                    </label>
                    <select
                        id="selectOption"
                        name="cars"
                        className={styles.select}
                        onChange={onChange}
                        value={value}
                    >
                        <option value={''} className={styles.option} disabled hidden>
                            Odaberi
                        </option>
                        {selectDropdownList.map((option, i) => (
                            <option value={option.value} key={i} className={styles.option}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                </>
            )}
        </div>
    )
}

export default SelectOptionsDropdown
