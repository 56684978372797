import History from '../models/History'
import { api } from '../shared/api'


class HistoryService {

    async getHistoryPosts() {
        const path = '/histories'

        const historyPosts = await api.get(path)

        if (historyPosts.history) {
            return historyPosts.history.map(history => new History(history))

        }
        return []
    }

    async postHistory(data) {
        const path = '/histories'

        const {
            year,
            historyText,
            historyTextEn
        } = data

        const payload = {
            year,
            history_text: historyText,
            history_text_en: historyTextEn
        }

        const history = await api.post(path, payload)

        return new History(history)
    }

    async getHistory(id) {
        const path = `/histories/${id}`

        const history = await api.get(path)

        return new History(history)

    }

    async updateHistory(data, id) {
        const path = `/histories/${id}`

        const {
            year, historyText, historyTextEn
        } = data

        const payload = {
            year,
            history_text: historyText,
            history_text_en: historyTextEn
        }

        const history = await api.put(path, payload)

        return new History(history)
    }

    async deleteHistory(id) {
        const path = `/histories/${id}`

        await api.delete(path)

    }

    async searchHistory(searchTerm) {
        const path = '/histories'

        const queryParams = {
            _sort: 'createdAt:DESC',
            year_contains: searchTerm
        }

        const histories = await api.get(path, queryParams)

        return histories.map(history => new History(history))
    }
}

export const historyService = new HistoryService()