import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styles from './Header.module.css'
import { AppContext } from '../../_context/AppContext'

const Header = ({ title, onMenuMobToggle }) => {
    const { user } = useContext(AppContext)
    return (
        <header className={styles.header}>
            <div className={styles.headerContainer}>
                <div className={styles.logoMenuContainer}>
                    <div className={styles.logo}>
                        <img src="/images/logo-bit-transparent.png" alt="logo" />
                    </div>
                    <div className={styles.mobMenuIcon} onClick={onMenuMobToggle}>
                        <i className="fas fa-bars"></i>
                    </div>
                </div>
                <h3 className={styles.title}>{title}</h3>
                <Link to="/profile" className={styles.userInfo}>
                    <span className={styles.icon}>
                        <i className="fas fa-user-alt"></i>
                    </span>
                    <span className={styles.userName}>{user.username}</span>
                </Link>
            </div>
        </header>
    )
}

export default Header
