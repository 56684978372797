import React from 'react'

import Input from '../../../components/Input/Input'

const FormInputs = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { phone, fax, street, city, email } = values

    return (
        <>
            <Input
                name="phone"
                id="phone"
                type="text"
                errors={errors}
                touched={touched}
                label="Broj telefona"
                setFieldValue={setFieldValue}
                value={phone}
            />
            <Input
                name="fax"
                id="fax"
                type="text"
                errors={errors}
                touched={touched}
                label="Fax"
                setFieldValue={setFieldValue}
                value={fax}
            />
            <Input
                name="street"
                id="street"
                type="text"
                errors={errors}
                touched={touched}
                label="Ulica i broj"
                setFieldValue={setFieldValue}
                value={street}
            />
            <Input
                name="city"
                id="city"
                type="text"
                errors={errors}
                touched={touched}
                label="Grad"
                setFieldValue={setFieldValue}
                value={city}
            />
            <Input
                name="email"
                id="email"
                type="email"
                errors={errors}
                touched={touched}
                label="Email"
                setFieldValue={setFieldValue}
                value={email}
            />
        </>
    )
}

export default FormInputs
