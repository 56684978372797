import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../../_context/AppContext'

import SubItemList from './SubItemList/SubItemList'

import styles from './Sidebar.module.css'

const sidebarItems = [
    {
        link: '/o-nama',
        title: 'O nama',
        icon: 'fas fa-edit',
    },
    {
        link: '/istorijat',
        title: 'Istorijat',
        icon: 'fas fa-history',
    },
    {
        link: '/oglasi',
        title: 'Oglasi',
        icon: 'fas fa-ad',
    },
    {
        link: '/javni-pozivi',
        title: 'Javni pozivi',
        icon: 'fas fa-file',
    },
    {
        link: '/galerija',
        title: 'Galerija',
        icon: 'far fa-image',
    },
    {
        title: 'Kontakti',
        icon: 'far fa-address-book',
        subItems: [
            {
                link: '/kontakti/direkcija',
                title: 'Direkcija',
                icon: 'far fa-address-book',
            },
            {
                link: '/kontakti/agencije',
                title: 'Agencije',
                icon: 'far fa-address-book',
            },
        ],
    },
    {
        title: 'Usluge',
        icon: 'fas fa-clipboard-list',
        subItems: [
            {
                link: '/usluge/o-uslugama',
                title: 'O uslugama',
                icon: 'fas fa-clipboard-list',
            },
            {
                link: '/usluge/transport',
                title: 'Transport',
                icon: 'fas fa-pallet',
            },
            {
                link: '/usluge/agencijske-usluge',
                title: 'Agencijske usluge',
                icon: 'fas fa-building',
            },
            {
                link: '/usluge/brodoremont',
                title: 'Brodoremont',
                icon: 'fas fa-tools',
            },
            {
                link: '/usluge/ostale-usluge',
                title: 'Ostale usluge',
                icon: 'fas fa-briefcase',
            },
        ],
    },
    {
        link: '/vesti',
        title: 'Novosti',
        icon: 'far fa-newspaper',
    },
    {
        link: '/nase-brojke',
        title: 'Naše brojke',
        icon: 'far fa-building',
    },
    {
        title: 'Sertifikati',
        icon: 'fas fa-certificate',
        subItems: [
            {
                link: '/sertifikati/sertifikati-dokumenta',
                title: 'Sertifikati - dokumenta',
                icon: 'fas fa-file-alt',
            },
            {
                link: '/sertifikati/dokumenta',
                title: 'Dokumenta',
                icon: 'fas fa-file-alt',
            },
            {
                link: '/sertifikati',
                title: 'O sertifikatima',
                icon: 'far fa-file-alt',
            },
        ],
    },
]

const Sidebar = ({ showMenuMob }) => {
    const [isSidebarHovered, setIsSidebarHovered] = useState(false)
    const { onUserLogOut } = useContext(AppContext)

    const onSidebarMouseEnter = () => {
        setIsSidebarHovered(true)
    }

    const onSidebarMouseLeave = () => {
        setIsSidebarHovered(false)
    }

    return (
        <aside
            className={`${styles.sidebar} ${showMenuMob ? styles.sidebarShowMob : ''}`}
            onMouseEnter={onSidebarMouseEnter}
            onMouseLeave={onSidebarMouseLeave}
        >
            <div className={styles.sidebarList}>
                {sidebarItems.map((item, i) => {
                    const { link, title, icon, subItems } = item
                    return subItems ? (
                        <SubItemList
                            key={i}
                            icon={icon}
                            title={title}
                            link={link}
                            subItems={subItems}
                            isSidebarHovered={isSidebarHovered}
                        />
                    ) : (
                        <Link to={link} className={`${styles.sidebarLink} `} key={i}>
                            <span className={styles.linkIcon}>
                                <i className={icon}></i>
                            </span>
                            <div className={styles.fullTitle}>
                                <span>{title}</span>
                            </div>
                        </Link>
                    )
                })}
            </div>
            <div className={styles.logOut} onClick={onUserLogOut}>
                <span className={styles.logOutIcon}>
                    <i className="fas fa-sign-out-alt"></i>
                </span>
                <div className={styles.logOutTitle}>
                    <span>Log Out</span>
                </div>
            </div>
        </aside>
    )
}

export default Sidebar
