import React from 'react'
import { Field } from 'formik'

import styles from './Input.module.css'

const Input = ({ name, id, value, type, errors, touched, label }) => {
    const isInvalid = errors && errors[name] && touched && touched[name]

    return (
        <div className={styles.inputWrapper}>
            <label htmlFor={id} className={styles.label}>
                {label}:
            </label>
            <Field name={name}>
                {({ field, }) => {
                    return(
                    <input
                        id={id}
                        type={type}
                        name={name}
                        className={`${styles.input} ${touched[name] && styles.active} ${
                            isInvalid && styles.invalid
                        }`}
                        onChange={field.onChange(field.name)}
                        autoComplete={'off'}
                        {...field}
                        value={value}
                    />
                )}}
            </Field>
            <span className={styles.errorMsg}>{isInvalid ? `* ${errors[name]}` : ''}</span>
        </div>
    )
}

export default Input
