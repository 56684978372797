import React, {useContext} from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AppContext } from '../_context/AppContext'

const PublicRoute = ({ component: Component, ...rest }) => {
    const {user} = useContext(AppContext)
    return (
        <Route
            {...rest}
            render={(props) => (!user ? <Component {...props} /> : <Redirect to="/o-nama" />)}
        />
    )
}

export default PublicRoute
