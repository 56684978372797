import React, { useState } from 'react'
import { publicCallsService } from '../../../services/publicCallsService'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'

import Page from '../../components/Page/Page'
import PublicCallForm from './PublicCallForm/AdvertisementForm'
import PublicCallsList from './PublicCallsList'

const AddPublicCall = () => {
    const initialValues = {
        title: '',
        titleEn: '',
        text: '',
        textEn: '',
        expDate: '',
    }
    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = async (values, { setStatus, setSubmitting, status }) => {
        setStatus('')
        setIsLoading(true)

        try {
            await publicCallsService.postPublicCall(values)

            setSubmitting(false)

            window.location.reload()
        } catch (err) {
            setStatus(err.message)
            console.error(err)
        }
    }

    return (
        <Page title="Javni pozivi - Novi unos">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <PublicCallsList />
                    <PublicCallForm initialValues={initialValues} onSubmit={onSubmit} />
                </>
            )}
        </Page>
    )
}

export default AddPublicCall
