import _ from 'lodash'
import Media from './Media'

class CertificateDoc{
    constructor({id,title,title_en, image, document, slug}) {
        this.id = id
        this.title = title
        this.titleEn = title_en
        this.image = image ? new Media(image) : null
        this.document = document ? new Media(document) : null
        this.slug = slug ? slug : id
    }

    get titleTruncated() {
        return _.truncate(this.title, {
            length: 40,
            omission: '...'
        })
    }
}

export default CertificateDoc