import _ from 'lodash'
import Media from './Media'

class GalleryItem {
    constructor(
        {
            id,
            title,
            title_en,
            subtitle,
            subtitle_en,
            image,
            category

        }) {
        this.id = id
        this.title = title
        this.titleEn = title_en
        this.subtitle = subtitle
        this.subtitleEn = subtitle_en
        this.image = image ? new Media(image) : null
        this.slug = id
        this.category = category
    }

    get imageUrl() {
        return this.image?.url
    }

    get titleTruncated() {
        return _.truncate(this.title, {
            length: 40,
            omission: '...'
        })
    }
}

export default GalleryItem