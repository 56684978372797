import React, { useEffect, useState } from 'react'

import SideList from '../../components/SideList/SideList'
import { advertisementsService } from '../../../services/advertisementsService'

const LIMIT = 30

const AdvertisementsList = () => {
    const [advertisementsList, setAdvertisementsList] = useState([])
    const [start, setStart] = useState(0)
    const [hasMore, setHasMore] = useState(false)

    useEffect(() => {
        const getAdsPosts = async () => {
            try {
                const response = await advertisementsService.getAdvertisements(0, LIMIT)
                const { total, posts } = response

                setAdvertisementsList(posts)
                setHasMore(total > posts.length)
                setStart(LIMIT)
            } catch (e) {
                console.error(e)
            }
        }
        getAdsPosts()
    }, [])

    const onLoadMore = async () => {
        try {
            const response = await advertisementsService.getAdvertisements(start, LIMIT)
            const { total, posts } = response

            setAdvertisementsList((prevState) => [...prevState, ...posts])
            setStart(start + LIMIT)
            setHasMore(total > [...advertisementsList, ...posts].length)
        } catch (e) {
            console.error(e)
        }
    }

    const onSearch = async (searchTerm) => {
        try {
            const ads = await advertisementsService.searchAdvertisements(searchTerm)
            setAdvertisementsList(ads)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <SideList
                list={advertisementsList}
                editLinkPath="/oglasi"
                onSearch={onSearch}
                withLoadMoreButton={hasMore}
                onLoadMore={onLoadMore}
            />
        </>
    )
}

export default AdvertisementsList
