import React from 'react'

import styles from './FormButton.module.css'

const FormButton = ({ text, type, onClick, iconClass, isRed = false, isGreen = false }) => {
    return (
        <div className={styles.buttonContainer} onClick={onClick}>
            <button
                type={type}
                className={`${styles.button} ${isRed ? styles.redButton : ''} ${
                    isGreen ? styles.greenButton : ''
                }`}
            >
                {iconClass && (
                    <span className="icon">
                        <i className={iconClass}></i>
                    </span>
                )}
                {text}
            </button>
        </div>
    )
}

export default FormButton
