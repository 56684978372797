import React from 'react'

import Input from '../../../components/Input/Input'
import TextEditor from '../../../components/TextEditor/TextEditor'

const FormInputsSr = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { serviceName, aboutServiceHome, aboutService, aboutServiceShort } = values

    return (
        <>
            <Input
                name="serviceName"
                id="serviceName"
                type="text"
                errors={errors}
                touched={touched}
                label="Naziv"
                setFieldValue={setFieldValue}
                value={serviceName}
            />
            <TextEditor
                data={aboutServiceHome}
                setFieldValue={setFieldValue}
                label="Tekst za naslovnu stranu"
                name="aboutServiceHome"
            />
            <TextEditor
                data={aboutServiceShort}
                setFieldValue={setFieldValue}
                label="Kratak tekst"
                name="aboutServiceShort"
            />
            <TextEditor
                data={aboutService}
                setFieldValue={setFieldValue}
                label="Tekst"
                name="aboutService"
            />
        </>
    )
}

export default FormInputsSr
