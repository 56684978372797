import React, { useContext } from 'react'

import { Formik, Form } from 'formik'
import * as yup from 'yup'

import { AppContext } from '../_context/AppContext'

import Page from '../components/Page/Page'

import styles from './UserProfile.module.css'
import Input from '../components/Input/Input'
import { userService } from '../../services/userService'

const UserProfile = () => {
    const { user, onUserLogOut } = useContext(AppContext)

    const payloadSchema = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().required(),
    })

    const initialValues = {
        email: user.email,
        username: user.username,
        password: '',
    }

    const onSubmit = async (values, { setStatus, setSubmitting }) => {
        setStatus('')

        try {
            const { email, username, password } = values
            const id = user.userId
            const updated = await userService.editUserProfile(id,email, username, password )


            onUserLogOut()

            setSubmitting(false)
        } catch (err) {
            setStatus(err.response.data.message)
            console.log(err)
        }
    }

    const _userProfileForm = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
        const { email, username, password } = values
        return (
            <div className={styles.userProfile}>
                <Form>
                    <div className={styles.userProfileForm}>
                        <Input
                            name="email"
                            id="email"
                            type="email"
                            errors={errors}
                            touched={touched}
                            label="Email"
                            setFieldValue={setFieldValue}
                            value={email}
                        />
                        <Input
                            name="username"
                            id="username"
                            type="text"
                            errors={errors}
                            touched={touched}
                            label="Username"
                            setFieldValue={setFieldValue}
                            value={username}
                        />
                        <Input
                            name="password"
                            id="password"
                            type="password"
                            errors={errors}
                            touched={touched}
                            label="Password"
                            setFieldValue={setFieldValue}
                            value={password}
                        />
                    </div>

                    <button type="submit" className={styles.button}>
                       Submit
                    </button>
                    {/* <Link to="/forgot-password" className={styles.forgotPass}>Forgot Password</Link> */}
                </Form>
            </div>
        )
    }

    return (
        <Page>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={payloadSchema}
                onSubmit={onSubmit}
            >
                {_userProfileForm}
            </Formik>
        </Page>
    )
}

export default UserProfile
