import React from 'react'

import Input from '../../../components/Input/Input'
import TextEditor from '../../../components/TextEditor/TextEditor'
import MultipleFilesInput from '../../../components/MultipleFilesInput/MultipleFilesInput'
import YouTubeVideo from '../../../components/YouTubeVideo/YouTubeVideo'

const FormInputsSr = ({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
    const { title, shortText, text, documents, youTubeVideo } = values

    return (
        <>
            <Input
                name="title"
                id="title"
                type="text"
                errors={errors}
                touched={touched}
                label="Naslov"
                setFieldValue={setFieldValue}
                value={title}
            />
            <TextEditor
                data={shortText}
                setFieldValue={setFieldValue}
                label="Kratak tekst"
                name="shortText"
            />
            <TextEditor data={text} setFieldValue={setFieldValue} label="Tekst" name="text" />
            <MultipleFilesInput
                setFieldValue={setFieldValue}
                fieldValueName="documents"
                fileIds={documents}
                label="Dokumenta"
                isDocuments
            />
            <YouTubeVideo
                name="youTubeVideo"
                id="youTubeVideo"
                label="YouTube video"
                errors={errors}
                touched={touched}
                value={youTubeVideo}
            />
        </>
    )
}

export default FormInputsSr
